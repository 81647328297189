import React, { useEffect, useState } from 'react'
import ReferralDetails from './ReferralDetails'
import { useSearchParams } from 'react-router-dom';
import CommonTable from '../../common/Table/CommonTable';
import { cityByCountryApi, countryListApi, getReferralDataApi } from '../../../utils/ApiEndpointFunctions';
import { useFormik } from 'formik';
import ReactSelect from 'react-select';
import RewardReferral from '../global/RewardReferral';
import { referralInitialValues,referralValidationSchema } from '../../common/Validation';
import Bonus from './Bonus';
import Discount from './Discount';
import { useAdminContext } from '../../../context/adminContext';

function Referrals() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("")
  const [tableLoader, setTableLoader] = useState(false);
  const [referralRowData, setReferralRowData] = useState();
  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const {adminDetail} = useAdminContext();

  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleCityChange = (city) => {
    searchFormik.setFieldValue('cityId', city)
  }

  const handleCountryChange = (country) => {
    console.log(country, 'this is count')
    setCityOptions([])
    searchFormik.setFieldValue('countryId', country);
    searchFormik.setFieldValue('cityId', null);
    filterCites(country.value)
  }

  const searchFormik = useFormik({
    initialValues: {
      countryId: adminDetail.role !== "superAdmin" && adminDetail.countryId ? { label: adminDetail.countryName, value: adminDetail.countryId } : null,
      cityId: null,
    },
  })


  const toggleTab = (tab) => {
    setActiveTab(tab);
    setSearchParams({ tab: tab });
  }

  const headerData = [
    { name: 'Referred By', key: 'referredByName' },
    { name: 'Referred To', key: 'referredToName' },    
    { name: 'Referral Code', key: 'referralCode' },    
  ]

  const setReferralRow = (data) => {
    setReferralRowData(data)
  }

  const getReferralData = async () => {
    setTableLoader(true);
    try {
      const res = await getReferralDataApi(searchFormik.values?.countryId?.label, searchFormik.values?.cityId?.label);
      if (res.status === 200) {
        
        setReferralRow(res.data.data);
      }
    } catch (error) {
      console.log(error)
    }

    setTableLoader(false);
  }

  useEffect(() => {
    getReferralData();
  }, [searchFormik.values])

  useEffect(() => {
    adminDetail.countryId && filterCites(adminDetail.countryId)
    adminDetail.countryId || filterCountry();
  }, [])
  



  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className='d-flex justify-content-between'>
          {searchParams.get("tab") !== "appversion" && <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${(activeTab === 'referrals' || searchParams.get("tab") == null) ? 'active' : ''}`}
                onClick={() => toggleTab('referrals')}
              >
                Referrals
              </button>
            </li>
          
            {/* <li className="nav-item">
              <button
                className={`nav-link ${searchParams.get("tab") === 'referral-setting' ? 'active' : ''}`}
                onClick={() => toggleTab('referral-setting')}
              >
                Referral
              </button>
            </li> */}
            <li className="nav-item">
              <button
                className={`nav-link ${searchParams.get("tab") === 'reward' ? 'active' : ''}`}
                onClick={() => toggleTab('reward')}
              >
                Reward-setting
              </button>
            </li>

            <li className="nav-item">
              <button
                className={`nav-link ${searchParams.get("tab") === 'bonus' ? 'active' : ''}`}
                onClick={() => toggleTab('bonus')}
              >
                Bonus
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${searchParams.get("tab") === 'discount' ? 'active' : ''}`}
                onClick={() => toggleTab('discount')}
              >
                Promotional discount
              </button>
            </li>
          </ul>}
        </div>

        {(searchParams.get("tab") === "referrals" || searchParams.get("tab") == null) && <div className='card p-3'>
          <div className="d-flex justify-content-end gap-3  align-items-center">
         
            <div className="d-flex align-items-center mb-3 gap-2">
              <label htmlFor="city" className="form-label">Country</label>
              <ReactSelect className='reactSelectMinWidth' isDisabled={adminDetail.role !== "superAdmin"} options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
              {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
            </div>

            <div className="d-flex align-items-center mb-3 gap-2">
              <label htmlFor="city" className="form-label">City</label>
              <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
              {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
            </div>
          </div>
          <CommonTable headers={headerData} data={referralRowData} loading={tableLoader} />
        </div>}

        {searchParams.get("tab") === 'reward' && (
          <div>
          <RewardReferral initialValues={referralInitialValues} validationSchema={referralValidationSchema} type="referral" tableType="referral" />
          </div>
        )}

        {searchParams.get("tab") === 'bonus' && (
          <div>
            <Bonus />
          </div>
        )}
        {searchParams.get("tab") === 'discount' && (
          <div>
            <Discount />
          </div>
        )}
              {/* {searchParams.get("tab") === 'reward' && (
          <div>
          <RewardReferral initialValues={referralInitialValues} validationSchema={referralValidationSchema} type="reward" tableType="reward" />
          </div>
        )} */}
      </div>

     
    </div>
  )
}

export default Referrals