import React, { useEffect, useState } from 'react';
import AddOrUpdateSuport from './AddOrUpdateSuport'; // Import the BlogAdd component
import { useFormik } from 'formik';
import { blogInitialValues, blogValidationSchema, supportInitialValues, supportValidationSchema } from '../../common/Validation';
import { addBlogApi, addOrUpdateSupportQuestionApi, deleteSupportQuestionApi, getBlogApi, getSupportQuestionListApi, termsAndConditionsListApi, updateBlogApi } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import Spinwheel from '../../common/Spinwheel';
import moment from 'moment';
import DeletedModal from '../../common/Modal/DeletedModal';
import { capitalizeWords } from '../../../utils/HelperFuncitons';
import { useSearchParams } from 'react-router-dom';

const Support = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [tableLoader, setTableLoader] = useState(true)
  const deleteModalId = "RideDeleteModal"
  const [selectedId, setSelectedId] = useState();
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("")
  const [typeList, setTypeList] = useState([])
  const getTermsAndConditionList = async () => {
    try {
      const res = await termsAndConditionsListApi()
      if (res.status === 200) {
        let list = res.data?.data
        setTypeList(list);
        if (searchParams?.get("tab") == null) {
          let tab = list[0]?.value
          setActiveTab(tab);
          setSearchParams({ tab: tab });
        } else {
          setActiveTab(searchParams?.get("tab"));
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getTermsAndConditionList()
  }, [])





  const getSupportQuestionList = async (activeTab) => {
    try {
      if (!activeTab) return;
      setTableLoader(true)
      const res = await getSupportQuestionListApi(activeTab);
      if (res.status === 200) {
        setTableData(res.data?.data)
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong")
    }
    setTableLoader(false)
  }

  const handleSumbit = async (values) => {
    try {
      console.log(values)
      setLoading(true)
      const res = await addOrUpdateSupportQuestionApi({...values, type: activeTab})
      if (res.status === 200) {
        toast.success(res.data?.message)
        getSupportQuestionList(activeTab);
        closeModal();
        document.getElementById("blogAddModal").click();
      }
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }
    setLoading(false)
  }

  const deleteHandler = async () => {
    setLoading(true)
    try {
      const res = await deleteSupportQuestionApi(selectedId)
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getSupportQuestionList(activeTab);
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setLoading(false)
  }

  const formik = useFormik({
    initialValues: supportInitialValues,
    onSubmit: handleSumbit,
    validationSchema: supportValidationSchema
  })
  const toggleTab = (tab) => {
    setActiveTab(tab);
    setSearchParams({ tab: tab });
  }


  useEffect(() => {
    getSupportQuestionList(activeTab)
  }, [activeTab])

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
           <div className="d-flex justify-content-between">
              <ul className="nav nav-tabs">
                {typeList?.filter(ele => { return ele.value !== "privacyPolicy" })?.map((ele, index) => {
                  return <li className="nav-item">
                    <button
                      className={`nav-link ${(activeTab === ele?.value || searchParams.get("tab") == null) ? 'active' : ''}`}
                      onClick={() => toggleTab(ele?.value)}
                    >
                      {ele?.label}
                    </button>
                  </li>
                })}
              </ul>
           </div>
            {activeTab && <div className="main-content">
              <div className='card'>
                <div className="card-header">
                  <div className="d-flex justify-content-end flex-wrap align-items-center mt-3 mt-lg-0">
                    <div style={{ display: "-webkit-inline-box" }}>
                      <div className="d-flex gap-2 justify-content-end align-items-center">
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#blogAddModal"
                          onClick={() => { openModal(); formik.resetForm() }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-md table-hover card-table">
                      <thead>
                        <tr>
                          <th scope="col">Question</th>
                          {/* <th scope="col">Content</th> */}
                          <th scope="col">Answer</th>
                          <th scope="col">Created date</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableLoader ? <tr>
                          <td colSpan={8} className='text-center'>
                            <Spinwheel />
                          </td>
                        </tr> :
                          tableData.length ? tableData.map((ele, index) => (
                            <tr key={ele.id}>
                              <td>{ele.question}</td>
                              <td>{ele.answer}</td>
                              <td>{moment(ele.createdAt).format("LL")}</td>
                              <td>
                                <div className="dropdown">
                                  <button
                                    className=" border-0 bg-white"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-three-dots-vertical"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                    </svg>
                                  </button>
                                  <ul
                                    className="dropdown-menu"
                                    style={{ zIndex: "1000" }}
                                  >
                                    <li className="dropdown-item">
                                      <div
                                        data-bs-toggle="modal"
                                        data-bs-target="#blogAddModal"
                                        onClick={() => { formik.setValues(ele); openModal() }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Edit
                                      </div>
                                    </li>
                                    <li className="dropdown-item" >
                                      <div
                                        data-bs-toggle="modal"
                                        data-bs-target={`#${deleteModalId}`}
                                        onClick={() => { setSelectedId({ id: ele.id }) }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Delete
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          )) :
                            <tr>
                              <td align="center" colSpan={"12"}>
                                <img src="/images/noData.png" style={{ width: "200px" }} alt="" />
                              </td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
      <AddOrUpdateSuport isOpen={modalIsOpen} onClose={closeModal} formik={formik} loading={loading} />
      <DeletedModal
        modalId={deleteModalId}
        message={"this question"}
        deletedHandler={deleteHandler}
        loader={loading}
      />

    </>
  );
};

export default Support;
