import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { cityByCountryApi, uploadFileApi } from '../../../utils/ApiEndpointFunctions'
import { S3_URL } from '../../../utils/BaseUrl'
import Spinwheel from '../Spinwheel'
import ReactSelect from 'react-select';

function CuisineOutFormModal({ formik, addLoader, modalId, countryOptions }) {


  const [cityOptions, setCityOptions] = useState([])
  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };


  const handleCountryChange = (country) => {

    setCityOptions([])
    formik.setFieldValue('countryId', country);
    formik.setFieldValue('cityId', {});

    filterCites(country.value)
  }

  const handleCityChange = (city) => {
    formik.setFieldValue('cityId', city)
  }

  const handleFileUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "restaurant")
        const res = await uploadFileApi(formData)
        formik.setFieldTouched(`image`, true)
        if (res.status === 200 && res.data?.status === 1) {
          formik.setFieldValue("image", res.data?.data);
          toast.success("File uploaded");
        }
      }
    } catch (error) {
      formik.setFieldTouched(`image`, true)
      console.log(error)
      if (error?.response === undefined) {
        toast.error("File size is too large")
      }
    }
  }
  
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2> {formik?.values?.id ? "Update" : "Add"} </h2>
                <form onSubmit={formik?.handleSubmit} >

                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">Country</label>
                    <ReactSelect options={countryOptions} value={formik?.values?.countryId?.label ? formik?.values?.countryId : null} isDisabled={formik?.values?.id} onChange={handleCountryChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                    {formik?.touched?.countryId && formik?.errors?.countryId ? (<div className='req-error'>{formik?.errors?.countryId}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">City</label>
                    <ReactSelect options={cityOptions} isDisabled={!formik?.values?.countryId || formik?.values?.id} value={formik?.values?.cityId?.label ? formik?.values?.cityId : null} onChange={handleCityChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
                    {formik?.touched?.cityId && formik?.errors?.cityId ? (<div className='req-error'>{formik?.errors?.cityId}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="thumbnail" className="form-label">Image</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {<img src={formik?.values?.image ? `${S3_URL}/${formik?.values?.image}` :"/images/image.svg"} width="70px" height="50px" style={{ objectFit: "contain" }} className="rounnded" alt="Selected file" />}
                        {formik.values.type === "video" &&
                          <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { formik.setFieldValue("thumbnail", "undefined/image.svg") }} >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </>
                        }
                      </div>
                      <div className="btn_upload">
                        <input type="file" id="thumbnail" className="form-control" name="thumbnail" accept="image/*" onChange={handleFileUpload} />
                        Upload
                      </div>
                    </div>
                    {formik?.touched?.image && formik?.errors?.image ? (<div className='req-error'>{formik?.errors?.image}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text" className="form-control" id="name" placeholder="Enter Name" name='name' value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                  </div>

                  <button type="submit" disabled={addLoader} className="btn btn-primary">
                    {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CuisineOutFormModal