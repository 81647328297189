import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { updateVehicleCategoryApi, getCategoryListApi, updateVehicleCategoryStatusApi, addVehicleCategoryApi, cityByCountryApi, countryListApi, addOrUpdateReferralDetailsApi, getReferralDetailsApi } from '../../../utils/ApiEndpointFunctions'
import { capitalizeWords } from '../../../utils/HelperFuncitons'

import DeletedModal from '../../common/Modal/DeletedModal'
import { addCategoryInitialValue, addCategoryValidationSchema, categoryInitialValue, categoryValidationSchema, referralInitialValues, referralValidationSchema } from '../../common/Validation'
import CategoryAddModal from '../../common/Modal/CategoryAddModal'
import { Link, useNavigate } from 'react-router-dom'
import CommonTable from '../../common/Table/CommonTable'
import ReactSelect from 'react-select';
import RewardAndReferralModal from '../../common/Modal/RewardAndReferralModal'
import { useAdminContext } from '../../../context/adminContext'

function RewardReferral({tableType,initialValues,validationSchema,type}) {
  const [loader, setLoader] = useState(true);
  const [addLoader, setAddLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const categoryModalId = "categoryFormModal"
  const rewardAndReferralModalId = "rewardAndReferralModalId"
  const deleteModalId = "categoryDeleteModal"
  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [submitLoader, setSubmitLoader] = useState(false);
  const navigate = useNavigate();
  const {adminDetail} = useAdminContext();



  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleCityChange = (city) => {
    searchFormik.setFieldValue('cityId', city)
  }

  const handleCountryChange = (country) => {
    console.log(country, 'this is count')
    setCityOptions([])
    searchFormik.setFieldValue('countryId', country);
    searchFormik.setFieldValue('cityId', null);
    filterCites(country.value)
  }
  const getRewardAndReferralList = async () => {
    try {
      setLoader(true)
      const res = await getReferralDetailsApi(searchFormik.values?.countryId?.value, searchFormik.values?.cityId?.value)
      if (res.status === 200) {
        setTableData(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const handleSubmit = async (values) => {
    try {
      setAddLoader(true)
      let payload = JSON.parse(JSON.stringify(values));
      payload.cityId = values.city.value
      payload.countryId = values.country.value
      const res = await updateVehicleCategoryApi(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        getRewardAndReferralList();
        document.getElementById(categoryModalId).click();
        formik.resetForm();
      }
    } catch (error) {
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
      console.log(error)
    }
    setAddLoader(false)
  }

  const deleteCategory = async () => {
    setAddLoader(true)
    try {
      const res = await updateVehicleCategoryStatusApi({ id: selectedId, status: false })
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getRewardAndReferralList();
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const updatingValueHandler = (value) => {
    if(value?.cityId && value?.countryId) {
      value.country = {
        label: value?.countryName, value: value?.countryId
      }
      value.city = {
        label: value?.cityName, value: value?.cityId
      }

    }else{
      value.countryId = null
      value.cityId = null
    }
    formik.setValues(value)
  };

  const searchFormik = useFormik({
    initialValues: {
      countryId: adminDetail.role !== "superAdmin" && adminDetail.countryId ? { label: adminDetail.countryName, value: adminDetail.countryId } : null,
      cityId: null,
    },    
  })


  const formik = useFormik({
    initialValues: categoryInitialValue,
    onSubmit: handleSubmit,
    validationSchema: categoryValidationSchema
  })

  const handleAddCategory = async (values) => {
    try {
      setAddLoader(true)
      let payload = JSON.parse(JSON.stringify(values));
      const res = await addVehicleCategoryApi(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        getRewardAndReferralList();
        document.getElementById(rewardAndReferralModalId).click();
        addCategoryFormik.resetForm();
      }
    } catch (error) {
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
      console.log(error)
    }
    setAddLoader(false)
  }

  const addCategoryFormik = useFormik({
    initialValues: addCategoryInitialValue,
    onSubmit: handleAddCategory,
    validationSchema: addCategoryValidationSchema
  })

  const referralFormik = useFormik({
    initialValues: { ...initialValues, countryId: adminDetail.role !== "superAdmin" && adminDetail.countryId ? { label: adminDetail.countryName, value: adminDetail.countryId } : null,  },
   validationSchema: validationSchema,
   onSubmit: async (values) => {
     try {
       setSubmitLoader(true)
       let payload = JSON.parse(JSON.stringify(values));
       if(!payload?.id){
         payload.cityId = values.cityId.value
         payload.countryId = values.countryId.value
       }
       payload.type = type
       const res = await addOrUpdateReferralDetailsApi(payload)
       if(res.status === 200) {
         toast.success(res.data.message);
         getRewardAndReferralList();
         document.getElementById(rewardAndReferralModalId).click();
         formik.resetForm();
       }

     } catch (error) {
       console.log(error)
     }
     setSubmitLoader(false)
   }
 })


  useEffect(() => {
    getRewardAndReferralList();
    
  }, [searchFormik.values])

  useEffect(() => {
    adminDetail.countryId && filterCites(adminDetail.countryId)
    adminDetail.countryId || filterCountry();
  }, [])

  const headerData = [
    { name: "S.No.", className: "col", key: "index" },
    { name: "Place", className: "col", key: "place" },
    { name: "Referral (driver)", className:"col", key:"referralPrice" },
    { name: "Signup (driver)", className: "col", key: "signupRewardPrice" },
    { name: "Referral (rider)", className: "col", key:"referralPriceRider" },
    { name: "Signup (rider)", className: "col", key: "signupRewardPriceRider" },
    { name: "Referral On/Off(driver)", className: "col", key: "referralOnOff" },
    { name: "Signup On/Off(driver)", className: "col", key: "signupOnOff" },
    { name: "Referral On/Off(rider)", className: "col", key: "referralOnOffRider" },
    { name: "Signup On/Off(rider)", className: "col", key: "signupOnOffRider" },
    // { name: "Action", className: "col", key: "action" },
  ]

  const categoryTableData = tableData?.map((ele, index) => ({
    index: index + 1,
    place: (
      <div
        className='text-primary'
        data-bs-toggle="modal"
        data-bs-target={`#${rewardAndReferralModalId}`}
        onClick={() => { referralFormik.setValues(ele) }}
        style={{ cursor: "pointer" }}
      >
        {ele?.cityName},{" "}{ele?.countryName}
      </div>
    ),
    ...ele,
    referralOnOff: ele?.referralOnOff ? "Active" : "Inactive",
    signupOnOff: ele?.signupOnOff ? "Active" : "Inactive",
    referralOnOffRider: ele?.referralOnOffRider ? "Active" : "Inactive",
    signupOnOffRider: ele?.signupOnOffRider ? "Active" : "Inactive",
    // action: (
    //   <>
    //     <div className="dropdown">
    //       <button
    //         className=" border-0 bg-white"
    //         type="button"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="16"
    //           height="16"
    //           fill="currentColor"
    //           className="bi bi-three-dots-vertical"
    //           viewBox="0 0 16 16"
    //         >
    //           <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    //         </svg>
    //       </button>
    //       <ul
    //         className="dropdown-menu"
    //         style={{ zIndex: "1000" }}
    //       >
    //         <li className="dropdown-item">
    //           <div
    //            data-bs-toggle="modal"
    //             data-bs-target={`#${rewardAndReferralModalId}`}
    //             onClick={() => { referralFormik.setValues(ele) }}
    //             style={{ cursor: "pointer" }}
    //           >
    //             Edit
    //           </div>
    //         </li>

    //          <li className="dropdown-item" >
    //           <div
    //             data-bs-toggle="modal"
    //             data-bs-target={`#${deleteModalId}`}
    //             onClick={() => { setSelectedId(ele?.id) }}
    //             style={{ cursor: "pointer" }}
    //           >
    //             Delete
    //           </div>
    //         </li> 
    //       </ul>
    //     </div>
    //   </>
    // )
  }))

  return (
    <div className='card p-3'><div className="d-flex justify-content-end flex-wrap align-items-center mt-3 mt-lg-0">
      <div style={{ display: "-webkit-inline-box" }}>
        <div className="position-relative my-3">

          <div className='d-flex gap-4'>
            <div className="d-flex align-items-center mb-3 gap-2">
              <label htmlFor="city" className="form-label">Country</label>
              <ReactSelect className='reactSelectMinWidth' isDisabled={adminDetail.role !== "superAdmin"} options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
              {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
            </div>

            <div className="d-flex align-items-center mb-3 gap-2">
              <label htmlFor="city" className="form-label">City</label>
              <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
              {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
            </div>

            <div>
              <div>
                <button
                  className="btn btn-primary"              
                  onClick={() => { searchFormik.resetForm(); }}
                  disabled={!searchFormik?.values?.countryId}
                >
                  Reset filter
                </button>
              </div>
            </div>

          <div>
              <button
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target={`#${rewardAndReferralModalId}`}
                onClick={() => { referralFormik.resetForm(); }}
              >
                Add
              </button>
          </div>

          </div>
         
        </div>
      </div>
    </div>

      <CommonTable headers={headerData} data={categoryTableData} loading={loader} />

      <RewardAndReferralModal modalId={rewardAndReferralModalId} addLoader={submitLoader} formik={referralFormik} type={type}/>
      
      <DeletedModal
        modalId={deleteModalId}
        message={"this Category"}
        deletedHandler={deleteCategory}
        loader={addLoader}
      />
    </div>
  )
}

export default RewardReferral