import React, { useEffect, useState } from 'react'
import Spinwheel from '../Spinwheel'
import { cityByCountryApi, countryListApi, defaultCategoryListOptionsApi } from '../../../utils/ApiEndpointFunctions';
import ReactSelect from 'react-select';
import { useAdminContext } from '../../../context/adminContext';

function CategoryAddModal(props) {
  const { formik, addLoader, modalId, tableType } = props;

  const [countryOptions, setCountryOptions] = useState([])
  const {adminDetail} = useAdminContext();
  const [cityOptions, setCityOptions] = useState([])
  const [defaultCategoryOptions, setDefaultCategoryOptions] = useState([])

  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };


  const handleCountryChange = (country) => {
   
    setCityOptions([])
    formik.setFieldValue('countryId', country);
    formik.setFieldValue('cityId', []);
    formik.setFieldValue('category', []);
    filterCites(country.value)
  }

  const handleCityChange = (city) => {
      formik.setFieldValue('cityId',[...city])
  }

  const handleCategoryChange = (category) => {
      formik.setFieldValue('category', [...category])
  }

  const getAllDefaultCategory = async() => {
    try {
      const res = await defaultCategoryListOptionsApi(tableType);
      if(res.status === 200){
        const option = res.data.data.map(el => ({label:el.category, value:el.id}))
        setDefaultCategoryOptions(option)
      }
    } catch (err) {
      console.log(err)
    }
  } 

  useEffect(() => {
    getAllDefaultCategory()
    adminDetail.countryId && filterCites(adminDetail.countryId)
    adminDetail.countryId || filterCountry();
  },[])

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>{formik?.values?.id ? "Update" : "Add"} Category</h2>
                <form onSubmit={formik?.handleSubmit} >

                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">Country</label>
                    <ReactSelect options={countryOptions} isDisabled={adminDetail.role !== "superAdmin"} value={formik?.values?.countryId} onChange={handleCountryChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                    {formik?.touched?.countryId && formik?.errors?.countryId ? (<div className='req-error'>{formik?.errors?.countryId}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">City</label>
                    <ReactSelect options={cityOptions} isDisabled={!formik?.values?.countryId} value={formik?.values?.cityId} onChange={handleCityChange} onBlur={formik?.handleBlur} isMulti noOptionsMessage={() => 'No Cities Available'} />
                    {formik?.touched?.cityId && formik?.errors?.cityId ? (<div className='req-error'>{formik?.errors?.cityId}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="category" className="form-label">Class</label>
                    <ReactSelect options={defaultCategoryOptions} value={formik?.values?.category} onChange={handleCategoryChange} onBlur={formik?.handleBlur} isMulti noOptionsMessage={() => 'No Categories Available'} />
                    {formik?.touched?.category && formik?.errors?.category ? (<div className='req-error'>{formik?.errors?.category}</div>) : null}
                  </div>

                  <button type="submit" disabled={addLoader} className="btn btn-primary">
                    {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default CategoryAddModal