import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useAdminContext } from '../../../context/adminContext';
import { addOrUpdateJobApi, changePartnerEarningWithdrawRequestStatusApi, cityByCountryApi, countryListApi, getJobListApi, partnerEarningWithdrawRequestApi } from '../../../utils/ApiEndpointFunctions';
import { capitalizeWords, dateFormat } from '../../../utils/HelperFuncitons';
import LimitPagination from '../../common/LimitPagination';
import CommonTable from '../../common/Table/CommonTable'
import ConfirmationModal from '../../common/ConfirmationModal';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import ReactSelect from 'react-select';
import AddOrUpdateJob from './AddOrUpdateJob';
import { jobInitialValue, jobValidationSchema } from '../../common/Validation';
import { S3_URL } from '../../../utils/BaseUrl';
import ImageViewModal from '../../common/Modal/ImageViewModal';

const Jobs = () => {
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [confirmStatus, setConfirmStatus] = useState({ status: "", id: 0 })
  const { limit, adminDetail } = useAdminContext();
  const addOrUpdateJobModalId = "addOrUpdateJobModal";
  const [ submitLoader, setSubmitLoader ] = useState(false);
  const imageViewModalId = "imageViewModalId"
  const [selectedImg, setSelectedImg] = useState("")
  

  const confirmApproveOrReject = (status, id) => {
    setConfirmStatus({ status, id })
    document.getElementById('confirmModalBtn').click()
  }

  const getAllJobList = async (selectedPage, limit) => {
    try {
      setLoader(true);
     
      const res = await getJobListApi()
      if (res.status === 200 || res.status === 201) {
        const usersData = res?.data?.data?.jobs;
        const totalLength = res?.data?.data?.totalLength;
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setTableData(usersData);
        setLoader(false);
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    getAllJobList(currentPage, limit)
    setSelectedPage(currentPage)
  };

  const getWalletAmount = (item) => {
    const role = item?.role?.[0]
    return role === "driver" ? (item?.driverDetails?.length ? item.driverDetails[0].totalEarnings : 0)
      : role === "hire" ? item?.rentalVendor?.totalEarnings
        : role === "restaurant" ? item?.restaurant?.totalEarnings
          : role === "salesAgent" ? item?.salesAgent?.totalEarnings
            : 0
  }

  const headerData = [
    { name: "S.NO", className: "col-1", key: "sNo" },
    { name: "Logo", className: "col-1", key: "logo" },
    { name: "Job Title", className: "col-1", key: "title" },
    { name: "Job Type", className: "col-1", key: "jobType" },
    { name: "Package", className: "col-1", key: "package" },
    { name: "Experience", className: "col-1", key: "experience" },
    { name: "Education", className: "col-1", key: "education" },
    { name: "Open", className: "col-1", key: "isOpen" },
    { name: "Action", className: "col-1", key: "action" },
  ]

  const RequestTableData = tableData.map((item, index) => ({
    sNo: index + 1,
    logo: <div className="avatar avatar-xs align-middle me-2">
              <img
                className="avatar-img rounded-circle"
                src={!item.logo ? "/images/profiledp.png" : `${S3_URL}/${item?.logo}`}
                alt="img"
                data-bs-toggle="modal"
                data-bs-target={`#${imageViewModalId}`}
                style={{ cursor: 'pointer' }}
                onClick={() => { setSelectedImg(!item.logo ? "/images/profiledp.png" : `${S3_URL}/${item?.logo}`) }}
              />
            </div>,
    title: item?.title ? capitalizeWords(item?.title) : "-",
    jobType: item.jobType ? capitalizeWords(item.jobType) : "-",
    experience: item?.experience ? item?.experience : "-",
    education: item?.education ? item.education : "-",
    isOpen: item?.isOpen ? "Open" : "Close",
    package: item?.isDisclose ? item.package : "Disclose",
    action: 
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          <li className="dropdown-item" 
            onClick={() => { formik.setValues(item); }}
            data-bs-toggle="modal"
            data-bs-target={`#${addOrUpdateJobModalId}`}
          >
            <div style={{ cursor: "pointer" }}
            >
              Edit
            </div>
          </li>
        </ul>
      </div>
  }))

  const handleChangeStatus = async () => {
    try {
      setLoader(true)
      await changePartnerEarningWithdrawRequestStatusApi(confirmStatus)
      getAllJobList();
      document.getElementById('confirmationModal').click()
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something Went Wrong")
      setLoader(false)
    }
  }

  const formik = useFormik({
    initialValues: jobInitialValue,
    onSubmit: async (values) => {

      console.log(values)
      try {
        setSubmitLoader(true)
        const res = await addOrUpdateJobApi(values)
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message)
          getAllJobList()
          document.getElementById(addOrUpdateJobModalId).click()
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Something Went Wrong")
        
      }
      setSubmitLoader(false)
    },
    validationSchema: jobValidationSchema
  })

  useEffect(() => {
    getAllJobList(selectedPage, limit);

  }, [])



  return (

    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* <BreadCrumb /> */}
            <div className="main-content">
              {/* HEADER */}
              <div className="row">
                <>
                  <div className="card">
                    <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0 py-4">
                      <LimitPagination getData={getAllJobList} />
                      <div style={{ display: "-webkit-inline-box" }}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target={`#${addOrUpdateJobModalId}`}
                          onClick={formik.resetForm}
                        >
                          Add 
                        </button>
                      </div>
                    </div>
                    <CommonTable headers={headerData} data={RequestTableData} loading={loader} />
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>

      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={selectedPage - 1}
          />
        </div>
      ) : (
        ""
      )}

      <button
        className="d-none"
        id="confirmModalBtn"
        data-bs-toggle="modal"
        data-bs-target="#confirmationModal">
        openmodal
      </button>
      <ConfirmationModal
        handleConfirm={handleChangeStatus}
        confirmStatus={confirmStatus}
        message={"Are You Sure?"}
      />

      <AddOrUpdateJob formik={formik} loading={submitLoader} modalId={addOrUpdateJobModalId}/>
      <ImageViewModal imgUrl={selectedImg} modalId={imageViewModalId} />
    </>

  )
}




export default Jobs