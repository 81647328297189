import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { uploadFileApi } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import { convertByteToMB } from '../../../utils/HelperFuncitons';
import Spinwheel from '../../common/Spinwheel';

const AddOrUpdateJob = ({modalId, formik, loading }) => {

  const fileInputRef = useRef(null);
  const fileUploadHandler = async (e) => {
    try {

      const FILE_ACCEPT = ["image/jpg", "image/jpeg", "image/png"]

      const file = e.target.files[0];
      if (file) {

        if (!FILE_ACCEPT.includes(file.type)) {
          toast.error("Invalid File Format")
          return;
        }

        const fileSizeInMB = convertByteToMB(file.size || 0);

        if (fileSizeInMB > 5) {
          return toast.error("File Size Exceeds. Max Image Should be of 5 MB")
        }

        formik.setFieldTouched('logo', true)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "driver")

        const res = await uploadFileApi(formData)
        if (res.status === 200 && res.data?.status === 1) {
          toast.success("File uploaded");
          formik.setFieldValue('logo', res.data?.data)
        }
      }

    } catch (error) {
      console.log(error)
      if (error?.response === undefined) {
        toast.error("There was an error uploading your file. Please try again later.")
      }
    }
  }



  const editor = useRef(null);

  const config = useMemo(() => {
    return {
      disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
      removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
      readonly: false,
      placeholder: 'Start writing...',
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      enter: "br",
      hidePoweredByJodit: true
    }
  }, []);


  return (
    <div className={`modal fade`} id={modalId} tabIndex="-1" aria-labelledby="blogAddModalLabel" aria-hidden="true" >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="blogAddModalLabel">{formik?.values?.id ? "Update" : "Add"} New Blog</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={formik?.handleSubmit}>
              {/* <div className="mb-3">
                <label htmlFor="image" className="form-label">Image:</label>
                <input type="file" className="form-control" id="image" name="image" onChange={fileUploadHandler} onBlur={formik?.handleBlur} />
                {formik?.touched?.image && formik?.errors?.image ? (<div className='req-error'>{formik?.errors?.image}</div>) : null}
              </div> */}

              <div className='mb-3'>
                <label className="block font-semibold">logo:</label>

                <div
                  className="border p-3 rounded cursor-pointer bg-gray-100 hover:bg-gray-200 text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => fileInputRef.current?.click()}
                >
                  {formik.values?.logo ? formik.values.logo?.split("/")?.[1] ? formik.values.logo?.split("/")?.[1] : formik.values.logo : "Choose a file"}
                </div>

                <input
                  type="file"
                  className="d-none"
                  ref={fileInputRef}
                  name="logo"
                  onChange={fileUploadHandler}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">Job title</label>
                <input type="text" className="form-control" id="title" name="title" placeholder='Enter' value={formik?.values?.title} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                {formik?.touched?.title && formik?.errors?.title ? (<div className='req-error'>{formik?.errors?.title}</div>) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="jobType" className="form-label">Job type</label>
                <input type="text" className="form-control" id="jobType" name="jobType" placeholder='Enter' value={formik?.values?.jobType} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                {formik?.touched?.jobType && formik?.errors?.jobType ? (<div className='req-error'>{formik?.errors?.jobType}</div>) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="package" className="form-label">Package(per anum) </label>
                <input type="text" className="form-control" id="package" name="package" placeholder='Enter' value={formik?.values?.package} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                {formik?.touched?.package && formik?.errors?.package ? (<div className='req-error'>{formik?.errors?.package}</div>) : null}
              </div>
              
              <div className="mb-3">
                <label htmlFor="location" className="form-label">Location</label>
                <input type="text" className="form-control" id="location" name="location" placeholder='Enter' value={formik?.values?.location} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                {formik?.touched?.location && formik?.errors?.location ? (<div className='req-error'>{formik?.errors?.location}</div>) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="experience" className="form-label">Experience</label>
                <input type="text" className="form-control" id="experience" name="experience" placeholder='Enter' value={formik?.values?.experience} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                {formik?.touched?.experience && formik?.errors?.experience ? (<div className='req-error'>{formik?.errors?.experience}</div>) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="education" className="form-label">Education</label>
                <input type="text" className="form-control" id="education" name="education" placeholder='Enter' value={formik?.values?.education} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                {formik?.touched?.education && formik?.errors?.education ? (<div className='req-error'>{formik?.errors?.education}</div>) : null}
              </div>



              <div>
                <label htmlFor="isDisclosed">Disclose</label>
                <div className="form-check form-switch ">
                  <input className="form-check-input" type="checkbox" checked={formik?.values?.isDisclosed} role="switch" id="isDisclosed" onChange={() => formik.setFieldValue("isDisclosed", !formik?.values?.isDisclosed)} />
                </div>
              </div>

              <div>
                <label htmlFor="isOpen">Open</label>
                <div className="form-check form-switch ">
                  <input className="form-check-input" type="checkbox" checked={formik?.values?.isOpen} role="switch" id="isOpen" onChange={() => formik.setFieldValue("isOpen", !formik?.values?.isOpen)} />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="content" className="form-label">Description</label>
                <JoditEditor
                  ref={editor}
                  value={formik?.values?.description}
                  // config={config}
                  onBlur={(e) => {
                    formik.setFieldTouched("description", true)
                    e === "<p><br></p>" ? formik.setFieldValue("description", "") : formik.setFieldValue("description", e);
                  }}
                  onChange={(newContent) => { }}
                />
                {formik?.touched?.description && formik?.errors?.description ? (<div className='req-error'>{formik?.errors?.description}</div>) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="responsibilities" className="form-label">Responsibilities</label>
                <JoditEditor
                  ref={editor}
                  value={formik?.values?.responsibilities}
                  // config={config}
                  onBlur={(e) => {
                    formik.setFieldTouched("responsibilities", true)
                    e === "<p><br></p>" ? formik.setFieldValue("responsibilities", "") : formik.setFieldValue("responsibilities", e);
                  }}
                  onChange={(newContent) => { }}
                />
                {formik?.touched?.responsibilities && formik?.errors?.responsibilities ? (<div className='req-error'>{formik?.errors?.responsibilities}</div>) : null}
              </div>
              <button type="submit" className="btn btn-primary" disabled={loading}>  {loading ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrUpdateJob;