import React from 'react'
export default function TopPerformerCard({ title, value, icon}) {
  return (
   <div className="">
   <div className="card shadow-sm border-0 rounded-3 p-3 d-flex gap-2 flex-row align-items-center">
     <div className="icon-box bg-primary text-white p-3 rounded-circle">
       {icon}
     </div>
     <div className="flex-grow-1">
       <p className="text-muted mb-1 fw-bold fs-3">{title}</p>
       <h5 className={`fw-bold fs-5`}>{value}</h5>
     </div>
   </div>
 </div>
  )
}