import React from 'react'
export default function CommonCard({ title, value, icon,isValueBold=true }) {
  return (
   <div className="">
   <div className="card shadow-sm border-0 rounded-3 p-3 d-flex flex-row align-items-center">
     <div className="flex-grow-1">
       <p className="text-muted mb-1">{title}</p>
       <h5 className={`${isValueBold ? "fw-bold fs-2" : "fs-5"} `}>{value}</h5>
     </div>
     <div className="icon-box bg-primary text-white p-3 rounded-circle">
       {icon}
     </div>
   </div>
 </div>
  )
}