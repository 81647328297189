import React, { useEffect, useState } from 'react';
import BlogAdd from './blogAdd'; // Import the BlogAdd component
import { useFormik } from 'formik';
import { blogInitialValues, blogValidationSchema } from '../../common/Validation';
import { addBlogApi, getBlogApi, updateBlogApi } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import Spinwheel from '../../common/Spinwheel';
import { S3_URL } from '../../../utils/BaseUrl';
import ImageViewModal from '../../common/Modal/ImageViewModal';
import moment from 'moment';

const Blog = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [tableLoader, setTableLoader] = useState(true)
   const [selectedImg, setSelectedImg] = useState("")
  const imageViewModalId = "imageViewModalId"

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const addBlog = (newBlog) => {
    setBlogData([...blogData, { id: blogData.length + 1, ...newBlog }]);
  };

  const getBlogList = async () => {
    try {
      setTableLoader(true)
      const res = await getBlogApi("", 10, 1 );
      if(res.status === 200) {
        setBlogData(res.data?.data)
      }       
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong")
    }
    setTableLoader(false)
  }

  const handleSumbit = async (values) => {
    try {
      console.log(values)
      setLoading(true)
      const res = values.id ? await updateBlogApi(values) : await addBlogApi(values)
      if(res.status === 200) {
        toast.success(res.data?.message)
        getBlogList();
        closeModal();
        document.getElementById("blogAddModal").click();
      }
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }
    setLoading(false)
  }

  const blogFormik = useFormik({
    initialValues: blogInitialValues,
    onSubmit:handleSumbit,
    validationSchema: blogValidationSchema
  })

  useEffect(() => {
    getBlogList()
  }, [])

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              <div className='card'>
                <div className="card-header">
                  <div className="d-flex justify-content-end flex-wrap align-items-center mt-3 mt-lg-0">
                    <div style={{ display: "-webkit-inline-box" }}>
                      <div className="d-flex gap-2 justify-content-end align-items-center">
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#blogAddModal"
                          onClick={() => { openModal(); blogFormik.resetForm() }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-md table-hover card-table">
                      <thead>
                        <tr>
                          <th scope="col">Heading</th>
                          {/* <th scope="col">Content</th> */}
                          <th scope="col">Image</th>
                          <th scope="col">Created date</th>
                          <th scope="col">Published</th>
                          <th scope="col">Published date</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableLoader ? <tr>
                          <td colSpan={8} className='text-center'>
                            <Spinwheel />
                          </td>
                        </tr> :
                        blogData.map((blog, index) => (
                          <tr key={blog.id}>
                            <td>{blog.heading}</td>
                            <td><div className="avatar avatar-xs align-middle me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={!blog.image ? "/images/profiledp.png" : `${S3_URL}/${blog?.image}`}
                                alt="img"
                                data-bs-toggle="modal"
                                data-bs-target={`#${imageViewModalId}`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => { setSelectedImg(!blog.image ? "/images/profiledp.png" : `${S3_URL}/${blog?.image}`) }}
                              />
                            </div></td>

                            <td>{moment(blog.createdAt).format("LL")}</td>   

                            <td>{blog.isPublish ? "Yes": "No"}</td>   
                            <td>{blog.publishedDate ? moment(blog.publishedDate).format("LL"): "-"}</td>   
                            <td>
                              <div className="dropdown">
                                <button
                                  className=" border-0 bg-white"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-three-dots-vertical"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  </svg>
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  style={{ zIndex: "1000" }}
                                >
                                  <li className="dropdown-item">
                                    <div
                                      data-bs-toggle="modal"
                                      data-bs-target="#blogAddModal"
                                      onClick={() => { blogFormik.setValues(blog); openModal() }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Edit
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageViewModal imgUrl={selectedImg} modalId={imageViewModalId} />
      <BlogAdd isOpen={modalIsOpen} onClose={closeModal} onAdd={addBlog} formik={blogFormik} loading={loading} />
    </>
  );
};

export default Blog;
