import React from 'react'
import Spinwheel from '../Spinwheel'
import Select from 'react-select';


function SentNotificaionModal({ sendNotificationHandler, selectTypeHandler, formik, loader, modalId }) {

  const selectionsOptions = [
    { label: 'User', value: 'user' },
    { label: 'Partner user', value: 'partnerUser' },
  ]
  

  return (
    <>

      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>Notification</h2>
                <form onSubmit={formik?.handleSubmit} >
                  <div className="mb-3">
                    <label htmlFor="type" className="form-label">User type</label>
                    <Select
                      id="type"
                      name="type"
                      options={selectionsOptions}
                      value={formik.values.type}
                      onChange={(selectedOption) =>
                        formik.setFieldValue('type', selectedOption)
                      }
                      onBlur={() => formik.setFieldTouched('type', true)}
                    />
                    {formik.touched.type && formik.errors.type ? (
                      <div className='req-error'>{formik.errors.type}</div>
                    ) : null}
                  </div>
                  <button type="submit" disabled={loader} className="btn btn-primary">
                    {loader ? <Spinwheel sizeClass={"spin-small"} /> : "Send"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SentNotificaionModal