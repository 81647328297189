import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import ReactPaginate from 'react-paginate';
import { useAdminContext } from '../../../../context/adminContext';
import { addOrUpdateMenuItemCategoryApi, cityByCountryApi, countryListApi, getMenuItemCategoryApi, updateMenuItemCategoryStatusApi } from '../../../../utils/ApiEndpointFunctions';
import { S3_URL } from '../../../../utils/BaseUrl';
import { capitalizeWords } from '../../../../utils/HelperFuncitons';
import ImageViewer from '../../../common/ImageViewer';
import LimitPagination from '../../../common/LimitPagination';
import CuisineOutFormModal from '../../../common/Modal/CuisineOutFormModal';
import DeletedModal from '../../../common/Modal/DeletedModal';
import ImageViewModal from '../../../common/Modal/ImageViewModal';
import Spinwheel from '../../../common/Spinwheel';
import { cuisineOutletInitialValue, cuisineOutletValidationSchema } from '../../../common/Validation';
import ReactSelect from 'react-select';

function OutletsTable() {
  const [loader, setLoader] = useState(true);
  const [addLoader, setAddLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedImg, setSelectedImg] = useState("")
  const addOutletModalId = "addOutletModal"
  const deleteModalId = "RideDeleteModal"
  const imageViewModalId = "imageViewModalId"
  const {limit, adminDetail} = useAdminContext();
  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])


  const filterCites = async (countryId) => {
      try {
        const res = await cityByCountryApi("", countryId)
        if (res.status === 200) {
          let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
          setCityOptions(cityOption);
        }
  
      } catch (error) {
        console.log(error)
      }
    };
  
    const filterCountry = async (inputValue) => {
      try {
        const res = await countryListApi(inputValue)
        if (res.status === 200) {
          let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
          setCountryOptions(cityOption);
        }
      } catch (error) {
        console.log(error)
      }
    };
  
  
    const handleCityChange = (city) => {
      searchFormik.setFieldValue('cityId', city)
    }
  
    const handleCountryChange = (country) => {
      console.log(country, 'this is count')
      setCityOptions([])
      searchFormik.setFieldValue('countryId', country);
      searchFormik.setFieldValue('cityId', null);
      filterCites(country.value)
    }
  
    const searchFormik = useFormik({
      initialValues: {
        countryId: adminDetail.role !== "superAdmin" && adminDetail.countryId ? { label: adminDetail.countryName, value: adminDetail.countryId } : null,
        cityId: null,
      },
    })
  
    useEffect(() => {
      adminDetail.countryId && filterCites(adminDetail.countryId)
      adminDetail.countryId || filterCountry();
    }, [])


  const getAllBestOutlets = async (selectedPage,limit) => {
    try {
      setLoader(true)
      const res = await getMenuItemCategoryApi(limit, selectedPage, searchFormik.values.countryId?.value, searchFormik.values.cityId?.value)
      if (res.status === 200 || res.status === 201) {
        const data = res?.data?.data?.list;
        const totalLength = res?.data?.data?.totalData;
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setTableData(data);
        setLoader(false);
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    getAllBestOutlets(currentPage,limit)
    setSelectedPage(currentPage)

  };

  const deleteOutlets = async () => {
    setAddLoader(true)
    try {
      const res = await updateMenuItemCategoryStatusApi({ id: selectedId, status: false })
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getAllBestOutlets(selectedPage,limit);
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const handleSubmit = async (values) => {
    try {
      setAddLoader(true)
      const payload = { ...values, countryId: values?.countryId?.value, cityId: values?.cityId?.value }
      const res = await addOrUpdateMenuItemCategoryApi(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        getAllBestOutlets(selectedPage,limit);
        document.getElementById(addOutletModalId).click();
        formik.resetForm();
      }
    } catch (error) {
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
      console.log(error)
    }
    setAddLoader(false)
  }

  const formik = useFormik({
    initialValues: cuisineOutletInitialValue,
    onSubmit: handleSubmit,
    validationSchema: cuisineOutletValidationSchema
  })

  useEffect(() => {
    getAllBestOutlets(selectedPage, limit);
  }, [searchFormik.values])

  return (
    <><div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
      <LimitPagination getData={getAllBestOutlets} />
      <div style={{ display: "-webkit-inline-box" }}>
        <div className="position-relative my-3  d-flex gap-2">
          {/* <input
            className="form-search me-3"
            type="search"
            name="searchField"
            id=""
            placeholder="Search by email"
            onChange={debouncedOnChange}
          // value = {search}
          /> */}

          <div className="d-flex align-items-center gap-2">
            <label htmlFor="city" className="form-label">Country</label>
            <ReactSelect className='reactSelectMinWidth' isDisabled={adminDetail.role !== "superAdmin"} options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
            {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
          </div>

          <div className="d-flex align-items-center gap-2">
            <label htmlFor="city" className="form-label">City</label>
            <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
            {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
          </div>

          <div>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => { searchFormik.resetForm(); }}
                disabled={!searchFormik?.values?.countryId}
              >
                Reset filter
              </button>
            </div>
          </div>


          <button
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target={`#${addOutletModalId}`}
            onClick={formik.resetForm}
          >
            Add
          </button>
        </div>
      </div>
    </div>
      <div className="table-responsive" style={{ overflowX: "inherit" }}>
        <table className="table table-md table-hover card-table">
          <thead>
            <tr>
              <th className='col-2'>
                <span className="list-sort text-muted">S.No</span>
              </th>
              <th className='col-2'>
                <span className="list-sort text-muted">Place</span>
              </th>
              <th className='col-2'>
                <span className="list-sort text-muted">Image</span>
              </th>
              <th className='col-2'>
                <span className="list-sort text-muted">Name</span>
              </th>
              <th className='col-2'>
                <span className="list-sort text-muted">Action</span>
              </th>
            </tr>
          </thead>
          {loader ? (
            <tbody>
              <tr>
                <td colSpan={8} className='text-center'>
                  <Spinwheel />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className="list fs-base">
              {tableData?.length > 0 ? (
                tableData?.map((ele, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div>{ele?.cityName && ele.countryName ? `${ele.cityName} | ${ele.countryName}` : "-"}</div>
                    </td>
                    <td><div className="avatar avatar-xs align-middle me-2">
                      <img
                        className="avatar-img rounded-circle"
                        src={!ele.image ? "/images/profiledp.png" : `${S3_URL}/${ele?.image}`}
                        alt="img"
                        data-bs-toggle="modal"
                        data-bs-target={`#${imageViewModalId}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => { setSelectedImg(!ele.image ? "/images/profiledp.png" : `${S3_URL}/${ele?.image}`) }}
                      />
                    </div>
                    </td>

                    <td>
                      <div>{ele?.name ? ele?.name : "-"}</div>
                    </td>

                    <td>
                      <div className="dropdown">
                        <button
                          className=" border-0 bg-white"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-three-dots-vertical"
                            viewBox="0 0 16 16"
                          >
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                          </svg>
                        </button>
                        <ul
                          className="dropdown-menu"
                          style={{ zIndex: "1000" }}
                        >
                          <li className="dropdown-item">
                            <div
                              data-bs-toggle="modal"
                              data-bs-target={`#${addOutletModalId}`}
                              onClick={() => { formik.setValues({ ...ele, countryId: { label: ele.countryName, value: ele.countryId }, cityId: { label: ele.cityName, value: ele.cityId } }) }}
                              style={{ cursor: "pointer" }}
                            >
                              Edit
                            </div>
                          </li>

                          <li className="dropdown-item" >
                            <div
                              data-bs-toggle="modal"
                              data-bs-target={`#${deleteModalId}`}
                              onClick={() => { setSelectedId(ele?.id) }}
                              style={{ cursor: "pointer" }}
                            >
                              Delete
                            </div>
                          </li>
                        </ul>
                      </div></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td align="center" colSpan="6">
                    <img
                      src="/images/noData.png"
                      style={{ width: "200px" }}
                      alt=""
                      width="50%"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      <CuisineOutFormModal modalId={addOutletModalId} formik={formik} addLoader={addLoader} countryOptions={countryOptions} />
      <ImageViewModal imgUrl={selectedImg} modalId={imageViewModalId} />
      <DeletedModal
        modalId={deleteModalId}
        message={"this Category"}
        deletedHandler={deleteOutlets}
        loader={addLoader}
      />
      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={selectedPage}
          />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default OutletsTable