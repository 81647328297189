import React, { useState } from 'react'
import { useAdminContext } from '../../../../context/adminContext';
import AddAdminModal from '../../../common/Modal/AddAdminModal';
import { useFormik } from 'formik';
import { addAdminInitialValues, addAdminInitialValuesValidationSchema } from '../../../common/Validation';
import { addOrUpdateAdministratorsApi } from '../../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import CommonTable from '../../../common/Table/CommonTable'

function AdministratorTable(props) {
  const { setConfirmStatus, loader, data, getAdminList, debouncedOnChange, deleteModalId } = props;
  const { limit } = useAdminContext();
  const [submitLoader, setSubmitLoader] = useState(false)

  const AddAdminModalId = "addAdminModalId"
  const confirmApproveOrReject = (status, id) => {
    setConfirmStatus({ status, id })
  }

  const handleSubmit = async (values) => {
    try {
      setSubmitLoader(true)
      const res = await addOrUpdateAdministratorsApi({ ...values, role : values.role?.value})
      if (res.status === 200) {
        console.log(res.data)
        toast.success(res.data?.message);
        getAdminList();
        document.getElementById(AddAdminModalId).click();
        formik.resetForm();
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data?.message ? error.response.data.message : "Something went wrong");
    }
    setSubmitLoader(false)

  }

  const formik = useFormik({
    initialValues: addAdminInitialValues,
    onSubmit: handleSubmit,
    validationSchema: addAdminInitialValuesValidationSchema
  })

  const adminLabels = (str) => {
    switch(str) {
      case "admin1": return "Admin 1";
      case "admin2": return "Admin 2";
      case "superAdmin": return "Super Admin";
      case "webAdmin": return "Web"
      default: return "Admin";
    }
  }

  const headerData = [
    { name: "S.No", key: "index", className: "col" },
    { name: "First Name", key: "firstName", className: "col" },
    { name: "Last Name", key: "lastName", className: "col" },
    { name: "Email", key: "email", className: "col" },
    { name: "Country", key: "country", className: "col" },
    { name: "Role", key: "role", className: "col" },
    { name: "Action", key: "action", className: "col" },
  ];

  const tableData = data.map((item, index) => ({
    index: index + 1,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
    country: item.country?.name || "-",
    role: item.role ? adminLabels(item.role) : "-",
    action: (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul className="dropdown-menu" style={{ zIndex: "1000" }}>
          <li
            data-bs-toggle="modal"
            data-bs-target={`#${deleteModalId}`}
            onClick={() => confirmApproveOrReject(false, item.id)}
          >
            <a className="dropdown-item" href="#">
              Delete
            </a>
          </li>
        </ul>
      </div>
    ),
  }));

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
        <div className="ms-auto" style={{ display: "-webkit-inline-box" }}>
          <div className="position-relative my-3">
            <div className="d-flex gap-2">
              <input
                className="form-search me-3"
                type="search"
                name="searchField"
                placeholder="Search by Name/ Email"
                onChange={debouncedOnChange}
              />
              <button
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target={`#${AddAdminModalId}`}
                onClick={() => formik.resetForm()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <CommonTable headers={headerData} data={tableData} loading={loader} />
      <AddAdminModal modalId={AddAdminModalId} formik={formik} loader={submitLoader} />
    </>
  );
}

export default AdministratorTable