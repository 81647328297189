import TextEditor from "../../common/TextEditor"

const PrivacyPolicy =()=>{
    return(
        <div className="main-content">
            <div className="container-fluid">
                <div className='d-flex justify-content-between'>
                    <ul className="nav nav-tabs">
                         <li className="nav-item">
                                <button
                                    className={`nav-link active`}
                                >
                                    Privacy Policy
                                </button>
                            </li>
                       

                    </ul>
                </div>
                <TextEditor type={"privacyPolicy"} />
            </div>


        </div>
    )
}

export default PrivacyPolicy