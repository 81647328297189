import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Link, useLocation } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import { capitalizeWords } from '../../../../utils/HelperFuncitons';
import LimitPagination from '../../../common/LimitPagination';
import { S3_URL } from '../../../../utils/BaseUrl';
import ImageViewModal from '../../../common/Modal/ImageViewModal';
import CommonTable from '../../../common/Table/CommonTable';
import moment from 'moment';
import ReactSelect from 'react-select';



function DriverTable(props) {
  const { setConfirmStatus, enabledDesabledModalId, setEnableDisable, getUsers, loader, users, selectedPage, page, totalCount, handlePageClick, debouncedOnChange, countryOptions, cityOptions, filterCountry, filterCites, handleCityChange, handleCountryChange, searchFormik } = props;
  const location = useLocation();
  const imageViewModalId = "imageViewModalId"
  const [selectedImg, setSelectedImg] = useState("")
  const { limit, adminDetail } = useAdminContext();
  
 

  const confirmApproveOrReject = (status, userId, role) => {
    setConfirmStatus({ status, userId, role })
    document.getElementById('confirmModalBtn').click()
  }

  const enableDisableHandler = (status, userId, role) => {
    setEnableDisable({ status, userId, type: "partnerUser" })
  }

  

  const headerData = [
    { name: "S.No", className: "col-1", key: "index" },
    { name: "Name", className: "col-3", key: "name" },
    { name: "Email ID", className: "col-1", key: "email" },
    { name: "Contact", className: "col-1", key: "phone" },
    { name: "Country", className: "col-1", key: "country" },
    { name: "City", className: "col-1", key: "city" },
    { name: "Role", className: "col-1", key: "role" },
    { name: "Approval", className: "col-1", key: "approvalStatus" },
    { name: "Registered On", className: "col-1", key: "createdAt" },
    { name: "Status", className: "col-1", key: "isActive" },
    { name: "Action", className: "col-1", key: "action" },
  ]

  const tableData = users?.map((user, index) => ({
    index: index + 1,
    name: (
      <>
        <div className="avatar avatar-xs align-middle me-2">
          <img
            className="avatar-img rounded-circle"
            src={!user.profileImage ? "/images/profiledp.png" : `${S3_URL}/${user?.profileImage}`}
            alt="img"
            data-bs-toggle="modal"
            data-bs-target={`#${imageViewModalId}`}
            style={{ cursor: 'pointer' }}
            onClick={() => { setSelectedImg(!user.profileImage ? "/images/profiledp.png" : `${S3_URL}/${user?.profileImage}`) }}
          />
        </div>
        <Link className="text-primary" to={
          user.role?.includes?.("driver") ? `/partners/driver/${user.id}`
            : user.role?.includes?.("hire") ? `/partners/rental/${user.id}`
              : user.role?.includes?.("restaurant") ? `/partners/restaurent/${user.id}`
                : user.role?.includes?.("salesAgent") ? `/partners/salesAgent/${user.id}`
                  : location.pathname + location.search
        } style={{ textWrap: "noWrap" }}>{capitalizeWords(user?.name)}</Link>
      </>
    ),
    email: user?.email || "-",
    phone: user?.phone || "-",
    country: user?.country || "-",
    city: user?.city || "-",
    role: user.role?.map(el => capitalizeWords(el === "salesAgent" ? "Sales Agent": el))?.join(", "),
    approvalStatus: user?.approvalStatus ? capitalizeWords(user?.approvalStatus) : "",
    createdAt: user?.createdAt ? moment(user?.createdAt).format("LL") : "-",
    isActive: user?.isActive ? "Enabled" : "Disabled",
    action: (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul className="dropdown-menu" style={{ zIndex: "1000" }}>
          {user?.approvalStatus === "pending" && <li onClick={() => confirmApproveOrReject('approved', user?.id, user?.lastActive)}>
            <a className="dropdown-item" href="#">
              Approve
            </a>
          </li>}
          {user?.approvalStatus === "pending" && <li onClick={() => confirmApproveOrReject('rejected', user?.id, user?.lastActive)}>
            <div className="dropdown-item" style={{cursor:"pointer"}} href="#">
              Reject
            </div>
          </li>}
          {user?.isActive === false && <li
            data-bs-toggle="modal"
            data-bs-target={`#${enabledDesabledModalId}`}
            onClick={() =>
              enableDisableHandler('approved', user?.id)
            }
          >
            <div className="dropdown-item" style={{ cursor: "pointer" }} >
              Enable
            </div>
          </li>}

          {user?.isActive === true && <li
            data-bs-toggle="modal"
            data-bs-target={`#${enabledDesabledModalId}`}
            onClick={() =>{
              enableDisableHandler('rejected', user?.id);
            }}
          >         
            <div className="dropdown-item"  style={{ cursor: "pointer" }} >
              Disable
            </div>
          </li>}
        </ul>
      </div>
    ) ,
  })) 

  useEffect(() => {
    adminDetail.countryId && filterCites(adminDetail.countryId)
    adminDetail.countryId || filterCountry();
  },[])


  return (
    < >
      <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
        <LimitPagination getData={getUsers} />
        <div className="d-flex gap-2" style={{ display: "-webkit-inline-box" }}>

          <div className="d-flex align-items-center gap-2">
            <label htmlFor="city" className="form-label">Country</label>
            <ReactSelect className='reactSelectMinWidth' isDisabled={adminDetail.role !== "superAdmin"} options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
            {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
          </div>

          <div className="d-flex align-items-center gap-2">
            <label htmlFor="city" className="form-label">City</label>
            <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
            {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
          </div>

          <div className='d-flex align-items-center gap-2'>
            <button
              className="btn btn-primary"
              onClick={() => { searchFormik.resetForm(); }}
              disabled={!searchFormik?.values?.countryId}
            >
              Reset filter
            </button>
          </div>

          <div className="position-relative my-3 d-flex justify-content-between ">
            <input
              className="form-search me-3"
              type="search"
              name="searchField"
              id=""
              placeholder="Search"
              onChange={debouncedOnChange}
            />
          </div>
          
        </div>
      </div>
      
      <CommonTable headers={headerData} data={tableData} loading={loader} />

      <ImageViewModal imgUrl={selectedImg} modalId={imageViewModalId} />
      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={selectedPage - 1}
          />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default DriverTable