import React from 'react';

import Spinwheel from '../../common/Spinwheel';

const AddOrUpdateSuport = ({ isOpen, onClose, formik, loading }) => {

  return (
    <div className={`modal fade ${isOpen ? 'show' : ''}`} id="blogAddModal" tabIndex="-1" aria-labelledby="blogAddModalLabel" aria-hidden={!isOpen} style={{ display: isOpen ? 'block' : 'none' }} role={isOpen ? "dialog" : undefined}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="blogAddModalLabel">{formik?.values?.id ? "Update" : "Add"} New Blog</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={formik?.handleSubmit}>

              <div className="mb-3">
                <label htmlFor="question" className="form-label">Question</label>
                <input type="text" className="form-control" id="question" name="question" placeholder='Enter' value={formik?.values?.question} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                {formik?.touched?.question && formik?.errors?.question ? (<div className='req-error'>{formik?.errors?.question}</div>) : null}
              </div>
              
              <div className="mb-3">
                <label htmlFor="answer" className="form-label">Answer</label>
                <textarea className='form-control' name="answer" id="answer" placeholder='Enter' value={formik?.values?.answer} onChange={formik?.handleChange} onBlur={formik?.handleBlur} ></textarea>
                {formik?.touched?.answer && formik?.errors?.answer ? (<div className='req-error'>{formik?.errors?.answer}</div>) : null}
              </div>
              <button type="submit" className="btn btn-primary" disabled={loading}>  {loading ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrUpdateSuport;