import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { adminChangePartnerApprovalStatus,  approveOrRejectRentalVehicleStatusApi,editRentalVendorApi, rentalvendorDetailsById } from '../../../utils/ApiEndpointFunctions';
import Spinwheel from '../../common/Spinwheel';
import { capitalizeWords, convertTo12HourFormat, dateFormat } from '../../../utils/HelperFuncitons';
import SelectedUserDetail from '../../common/SelectedUserDetail';
import { S3_URL } from '../../../utils/BaseUrl';
import ConfirmationModal from '../../common/ConfirmationModal';
import PdfSvgIcon from '../../common/PdfSvgIcon';
import ImageViewModal from '../../common/Modal/ImageViewModal';
import toast from 'react-hot-toast';
import moment from 'moment';
import CommonTable from '../../common/Table/CommonTable';
import ThreeDotsIcon from '../../common/ThreeDotsIcon';
import { useFormik } from 'formik';
import { rateInitialValues, rateValidationSchema } from '../../common/Validation';
import { useAdminContext } from '../../../context/adminContext';

const RentalVendorDetails = (props) => {
    const params = useParams();
    const [id, setId] = useState(params?.id)
    const [rentalVendorDetails, setRentalVendorDetails] = useState({});
    const [vehicleDetails, setVehicleDetails] = useState([]);
    const [bankDetails, setBankDetails] = useState([]);
    const [loader, setLoader] = useState(true);
    const [rateLoader, setRateLoader] = useState(false);
    const [activeTab, setActiveTab] = useState('driver');
    const [confirmStatus, setConfirmStatus] = useState({ status: "", userId: "", role: "hire" });
    const [selectedImg, setSelectedImg] = useState("")
    const confirmationModalId2 = "confirmationModal2"
    const [keyNameForUpdate, setKeyNameForUpdate] = useState("")
    const [selectVehicle, setSelectVehicle] = useState({});
    const { adminDetail } = useAdminContext();

    const fetchDriverDetails = async () => {
        try {

            const res = await rentalvendorDetailsById(id);
            if (res.status === 200) {
                const { vehicles, details } = res.data.data;

                const { id, role, phone, rentalVendor, bankDetails, partnerUserDetails } = details
                setRentalVendorDetails({ id, phone, role, ...partnerUserDetails[0], vendorStatus: rentalVendor.status });
                rateFormik.setValues({id : id, commissionFee: rentalVendor.commissionFee, discount: rentalVendor.discount})
                setVehicleDetails(vehicles);
                setBankDetails(bankDetails)

            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    };



    const handleChangeStatus = async () => {
        try {
            setLoader(true)
            await adminChangePartnerApprovalStatus(confirmStatus)
            fetchDriverDetails();
            document.getElementById('confirmationModal').click()
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something Went Wrong")
            setLoader(false)
        }
    }

    const openModal = () => {
        document.getElementById('confirmationModal2Btn').click()
    }

    useEffect(() => {
        if (id) {
            fetchDriverDetails();
        }
        
    }, [id]);

    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    const handleChangeDocumentStatus = async (status, keyName) => {
        const promise = approveOrRejectRentalVehicleStatusApi({ userId: id, keyName: keyName, status: status, rentalVehicleId: selectVehicle.id });
        toast.promise(
            promise,
            {
                loading: 'Updating document status...',
                success: (res) => {
                    if (res.status === 200) {
                        vehicleDetails.filter(ele => ele.id === selectVehicle.id)[0][keyName] = res.data.docStatus
                        setSelectVehicle({ ...selectVehicle, [keyName]: res.data.docStatus })
                        if (res.data.rentalVechileStatus) setRentalVendorDetails({ ...rentalVendorDetails, vendorStatus: res.data.rentalVechileStatus })
                        return res.data.message || 'Success';
                    }
                },
                error: (err) => {
                    return err.message || 'Something Went Wrong'
                }
            }
        )
    };

    const DocumentDisplay = ({ title, document, setSelectedImg, status, keyName, validUpto }) => {
        if (!document) {
            return (
                <tr>
                    <td>{title}</td>
                    <td className='text-center'>{`${title} not available`}</td>
                </tr>
            );
        }

        const isPdf = document ? Array.isArray(document) ? document[0]?.split(".").slice(-1)[0] === "pdf" : document.split(".").slice(-1)[0] === "pdf" : "";

        return (
            <tr>
                <td>{title}</td>
                <td className='text-center'>
                    {isPdf ? (
                        <Link to={`${S3_URL}/${document}`} className="text-primary">
                            <PdfSvgIcon width="100" height="100" />
                        </Link>
                    ) : (Boolean(Array.isArray(document) ? document.length : document) ?
                        <div
                            onClick={() => setSelectedImg(Array.isArray(document) ? document : `${S3_URL}/${document}`)}
                            data-bs-toggle="modal"
                            data-bs-target={`#imageViewModal`}
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src={`${S3_URL}/${Array.isArray(document) ? document[0] : document}`}
                                alt={title}
                                data-bs-toggle="modal"
                                data-bs-target={`#imageViewModal`}
                                style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
                                onClick={() => setSelectedImg(Array.isArray(document) ? document : `${S3_URL}/${document}`)}
                            />
                            <div className='text-primary fs-5'>{(Boolean(Array.isArray(document)) && (document.length > 1)) && `View more`}</div>
                        </div>
                    : "File not found")}
                </td>
                <td className='text-center'>{validUpto ? dateFormat(validUpto) : ""}</td>
                <td className='text-center'>{capitalizeWords(status) || "-"}</td>
                {status !== "pending" && <td className='text-center'>
                    <div className="dropdown">
                        <button
                            className=" border-0 bg-white"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <ThreeDotsIcon />
                        </button>
                        <ul
                            className="dropdown-menu"
                            style={{ zIndex: "1000" }}
                        >
                            {(status === "rejected" || status === "submitted") && <li
                                onClick={() => {
                                    if (moment().toISOString() < moment(validUpto).toISOString()) {
                                        handleChangeDocumentStatus('approved', keyName)
                                    } else {
                                        setKeyNameForUpdate(keyName)
                                        openModal()
                                    }
                                }}
                            >
                                <a className="dropdown-item" href="#">
                                    Approve
                                </a>
                            </li>}

                            {(status === "approved" || status === "submitted") && <li
                                onClick={() =>
                                    handleChangeDocumentStatus('rejected', keyName)
                                }
                            >
                                <a className="dropdown-item" href="#">
                                    Reject
                                </a>
                            </li>}
                        </ul>
                    </div>
                </td>}
            </tr>
        );
    };



    const vehicleHeaderData = [
        { name: "Year", className: "list-sort text-muted", key: "year" },
        { name: "Class", className: "list-sort text-muted", key: "category" },
        { name: "Type", className: "list-sort text-muted", key: "make" },
        { name: "Model", className: "list-sort text-muted", key: "model" },
        { name: "Color", className: "list-sort text-muted", key: "color" },
        { name: "Registration Number", className: "list-sort text-muted", key: "vehicleNumber" },
        { name: "Documents", className: "list-sort text-muted", key: "action" },
    ]

    const vehicleTableData = vehicleDetails.map((vhcl, index) => {
        return {
            licenseNo: vhcl.licenseNo,
            year: vhcl.year,
            category: vhcl?.category,
            make: vhcl?.make,
            model: vhcl?.model,
            color: capitalizeWords(vhcl?.color) || "",
            vehicleNumber: vhcl?.vehicleNumber || "",
            vehicleCertificate: vhcl?.vehicleCertificate?.split(".")?.slice(-1)?.includes("pdf") ?
                <Link to={`${S3_URL}/${vhcl?.vehicleCertificate}`}  > <PdfSvgIcon width="100" height="100" /></Link>
                :
                <img
                    src={vhcl?.vehicleCertificate ? `${S3_URL}/${vhcl?.vehicleCertificate}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg"}
                    alt={"img"}
                    data-bs-toggle="modal"
                    data-bs-target={`#imageViewModal`}
                    style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
                    onClick={() => { setSelectedImg(vhcl?.vehicleCertificate ? `${S3_URL}/${vhcl?.vehicleCertificate}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg") }}
                />,
            vehicleInsurance: vhcl?.vehicleInsurance?.split(".")?.slice(-1)?.includes("pdf") ?
                <Link to={`${S3_URL}/${vhcl?.vehicleInsurance}`}> <PdfSvgIcon width="100" height="100" /></Link>
                :
                <img
                    src={vhcl?.vehicleInsurance ? `${S3_URL}/${vhcl?.vehicleInsurance}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg"}
                    alt={"img"}
                    data-bs-toggle="modal"
                    data-bs-target={`#imageViewModal`}
                    style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
                    onClick={() => { setSelectedImg(vhcl?.vehicleInsurance ? `${S3_URL}/${vhcl?.vehicleInsurance}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg") }}
                />,
            action: <div style={{ cursor: "pointer" }} className='text-primary' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightCites" onClick={() => { setSelectVehicle(vhcl) }}>View</div>
        }
    })


    const bankDetailsHeaderData = [
        { name: "Account Holder Namer", className: "list-sort text-muted", key: "name" },
        { name: "Bank Name", className: "list-sort text-muted", key: "bankName" },
        { name: "IFSC", className: "list-sort text-muted", key: "Ifsc" },
        { name: "Account Number", className: "list-sort text-muted", key: "accountNo" },
        { name: "Account Type", className: "list-sort text-muted", key: "accountType" },
    ]

    const bankDetailsTableData = bankDetails?.map((bnk, index) => ({
        name: rentalVendorDetails.name || "",
        bankName: bnk.name || "",
        Ifsc: bnk.Ifsc || "",
        accountNo: bnk.accountNo || "",
        accountType: bnk.accountType || "",
    }))

    const profileData = [
        { name: "Email", data: rentalVendorDetails.email, },
        { name: "Phone", data: rentalVendorDetails.phone, },
        { name: "Alternate Phone", data: rentalVendorDetails.alternateMobile, },
        { name: "NIN", data: rentalVendorDetails.NIN, },
        { name: "Status", data: capitalizeWords(rentalVendorDetails.vendorStatus), },
        { name: "Role", data: rentalVendorDetails.role?.map(el => capitalizeWords(el))?.join(", "), },
        { name: "Gender", data: rentalVendorDetails.gender, },
        { name: "City", data: rentalVendorDetails.city, },
        { name: "State", data: rentalVendorDetails.state, },
        { name: "Country", data: rentalVendorDetails.country, },
        { name: "Zip Code", data: rentalVendorDetails.zip, },
        { name: "Address", data: <div>{rentalVendorDetails.permanentAddress && `${rentalVendorDetails.permanentAddress}, `}{rentalVendorDetails.state && `${rentalVendorDetails.state}, `}{rentalVendorDetails.city && `${rentalVendorDetails.city}, `}{rentalVendorDetails.zip && `${rentalVendorDetails.zip}`}</div>, },

    ]

    const rateSubmit = async (values) => {
        setRateLoader(true)
        try {
            const res = await editRentalVendorApi(values)
            if(res.status === 200) {

                toast.success(res.data.message)
            }        
        } catch (error) {
            toast.error("Failed to update rate")

        }

        setRateLoader(false)

        console.log(values)
    }

    const rateFormik = useFormik({
        initialValues: rateInitialValues,
        validationSchema: rateValidationSchema,
        onSubmit: rateSubmit
    })


    const tabData = [
        {
            name: "Profile Details", tab: "driver", setTab: toggleTab, data: <div className="row card">
                <div className="row mt-3">
                    <div className="user-details">
                        <table className="table table-md table-hover card-table">
                            <tbody>
                                {
                                    profileData.map((ele, index) => (
                                        <tr key={index}>
                                            <td>{ele.name}:</td>
                                            <td>{ele.data}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        },
        { name: "Vehicle Details", tab: "vehicle", setTab: toggleTab, data: <div className='p-3 card'><CommonTable headers={vehicleHeaderData} data={vehicleTableData} /> </div> },
        {
            name: "Bank Details", tab: "bankDetails", setTab: toggleTab, data: <div className='p-3 card'> <CommonTable headers={bankDetailsHeaderData} data={bankDetailsTableData} /> </div>
        },
        adminDetail?.role === "superAdmin" && {
            name: "Rate", tab: "rate", setTab: toggleTab, data:
                <div className="card">
                    <div className="card-header" style={{ backgroundColor: "var(--color-card-bg)" }}>
                        Rate
                    </div>
                    <div className="card-body" >
                        <div className='p-5'>
                            <form onSubmit={rateFormik.handleSubmit}>
                                <div className="form-group row row-cols-4">
                                    <div className='d-flex gap-3'>

                                        <label htmlFor='commissionFee' className=" col-form-label">Commission</label>
                                        <div className="">
                                            <input type="text" className="form-control" id='commissionFee' name='commissionFee' placeholder="0" onChange={rateFormik.handleChange} onBlur={rateFormik.handleBlur} value={rateFormik.values.commissionFee} />
                                            {rateFormik.touched?.commissionFee && rateFormik.errors?.commissionFee ? (<div className='req-error'>{rateFormik?.errors?.commissionFee}</div>) : null}
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3'>

                                        <label htmlFor='discount' className=" col-form-label">Discount</label>
                                        <div className="">
                                            <input type="text" className="form-control" id='discount' placeholder="0" name='discount' onChange={rateFormik.handleChange} onBlur={rateFormik.handleBlur} value={rateFormik.values.discount}/>
                                            {rateFormik.touched?.discount && rateFormik.errors?.discount ? (<div className='req-error'>{rateFormik?.errors?.discount}</div>) : null}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end"  >
                                        <button type="submit" className="btn btn-sm btn-primary" disabled={rateLoader}>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        },

    ]

    



    return (
        <div className="main-content">
            <div className="container-fluid">

                {loader ? (
                    <div className="text-center">
                        <Spinwheel />
                    </div>
                ) : (
                    <>
                        <div className="row align-items-end">
                            <div className="col-auto">
                                <div className="avatar avatar-xxl header-avatar-top mt-3">
                                    <img src={rentalVendorDetails?.profileImage ? `${S3_URL}/${rentalVendorDetails?.profileImage}` : "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"} alt="profile" className="avatar-img rounded-circle border border-4 border-body" />
                                </div>
                            </div>
                            <div className="col mb-3 ms-n3 ms-md-n2">
                                <h1 className="header-title">
                                    {capitalizeWords(rentalVendorDetails.name)}
                                </h1>
                            </div>
                            <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                                {(rentalVendorDetails.vendorStatus === "pending") ? <><button type="button" className="btn btn-outline-primary me-2" data-bs-toggle="modal"
                                    data-bs-target={`#confirmationModal`}
                                    onClick={(e) => { setConfirmStatus(prevState => ({ ...prevState, status: "approved", userId: id })) }}>Approve</button>
                                    <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal"
                                        data-bs-target={`#confirmationModal`}
                                        onClick={(e) => { setConfirmStatus(prevState => ({ ...prevState, status: "rejected", userId: id })) }}>Reject</button></> :
                                    <div className={`badge fs-4  ${rentalVendorDetails.vendorStatus === "approved" ? "bg-primary-soft" : "bg-secondary-soft"}`}>{capitalizeWords(rentalVendorDetails.vendorStatus)}</div>
                                }
                            </div>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <ul className="nav nav-tabs">
                                {
                                    tabData.map((ele, index) => (
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${activeTab === ele.tab ? 'active' : ''}`}
                                                onClick={() => ele.setTab(ele.tab)}
                                            >
                                                {ele.name}
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div>
                            </div>
                        </div>

                        {
                            tabData.map((ele) => (
                                activeTab === ele.tab ? ele.data : null
                            ))
                        }
                    </>
                )}

                <div className="offcanvas offcanvas-end" style={{ width: "45%" }} tabIndex="-1" id="offcanvasRightCites" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <h1 id="offcanvasRightLabel">Documents</h1>
                        <button type="button" className="btn-close text-reset" id="offcanvasRightcoutry" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <div className="card">
                            <div className="card-body">
                                <div className="row  ">
                                    <div className="mt-3 table-responsive  " style={{ overflowX: "inherit" }}>
                                        <table className="table table-md table-hover card-table">
                                            <thead>
                                                <tr>
                                                    <th> <span className="list-sort text-muted">Type</span></th>
                                                    <th className='text-center'> <span className="list-sort text-muted">File</span></th>
                                                    <th className='text-center'> <span className="list-sort text-muted">Valid Upto</span></th>
                                                    <th className='text-center'> <span className="list-sort text-muted">Status</span></th>
                                                    <th className='text-center'> <span className="list-sort text-muted">Action</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <DocumentDisplay
                                                    title="Vehicle Inssurance"
                                                    document={selectVehicle?.vehicleInsurance}
                                                    setSelectedImg={setSelectedImg}
                                                    status={selectVehicle?.vehicleInsuranceApprovalStatus}
                                                    keyName={"vehicleInsuranceApprovalStatus"}
                                                    validUpto={selectVehicle?.insuranceCertificateValidUpto}
                                                />
                                                <DocumentDisplay
                                                    title="Vehicle Certificate"
                                                    document={selectVehicle?.vehicleCertificate}
                                                    setSelectedImg={setSelectedImg}
                                                    status={selectVehicle?.vehicleCertificateApprovalStatus}
                                                    keyName={"vehicleCertificateApprovalStatus"}
                                                    validUpto={selectVehicle?.vehicleCertificateValidUpto}
                                                />
                                                <DocumentDisplay
                                                    title="Vehicle Image"
                                                    document={selectVehicle?.vehicleImage}
                                                    setSelectedImg={setSelectedImg}
                                                    status={selectVehicle?.vehicleImageApprovalStatus}
                                                    keyName={"vehicleImageApprovalStatus"}
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <ConfirmationModal
                    handleConfirm={handleChangeStatus}
                    confirmStatus={confirmStatus}
                />
                <ImageViewModal imgUrl={selectedImg} modalId={"imageViewModal"} />
                <button id="confirmationModal2Btn" className='d-none' data-bs-toggle="modal" data-bs-target={`#${confirmationModalId2}`}></button>
                <ConfirmationModal modalId={confirmationModalId2} message={<>The document is already expired!<br /> Do you still want to continue??</>} handleConfirm={() => handleChangeDocumentStatus("approved", keyNameForUpdate)} title={"Warning!"} confirmStatus={""} />
            </div>
        </div>

    );
};

export default RentalVendorDetails;
