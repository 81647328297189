import React, { useEffect, useState } from 'react'
import TimePicker from 'react-time-picker'
import Spinwheel from '../Spinwheel'
import Select from 'react-select';
import ReactSelect from 'react-select';
import { cityByCountryApi, countryListApi } from '../../../utils/ApiEndpointFunctions';
import { useAdminContext } from '../../../context/adminContext';

function AddBonusFormModal(props) {
  const { formik, addLoader, modalId, countryOptions } = props
  // const [countryOptions, setCountryOptions] = useState([])
  const { adminDetail } = useAdminContext();
  const [cityOptions, setCityOptions] = useState([])
  const durationOptions = [
    { value: 'period', label: 'Period' },
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
  ];

  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };


  const handleCountryChange = (country) => {

    setCityOptions([])
    formik.setFieldValue('countryId', country);
    formik.setFieldValue('cityId', {});
   
    filterCites(country.value)
  }

  const handleCityChange = (city) => {
    formik.setFieldValue('cityId', city)
  }

  useEffect(() => {
    adminDetail.countryId && filterCites(adminDetail.countryId)
  }, [])



  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>Add Bonus</h2>
                <form onSubmit={formik?.handleSubmit} >

                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">Country</label>
                    <ReactSelect options={countryOptions} value={formik?.values?.countryId} isDisabled={formik?.values?.id || adminDetail.role !== "superAdmin"} onChange={handleCountryChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                    {formik?.touched?.countryId && formik?.errors?.countryId ? (<div className='req-error'>{formik?.errors?.countryId}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">City</label>
                    <ReactSelect options={cityOptions} isDisabled={!formik?.values?.countryId || formik?.values?.id} value={formik?.values?.cityId?.label ? formik?.values?.cityId : null} onChange={handleCityChange} onBlur={formik?.handleBlur}  noOptionsMessage={() => 'No Cities Available'} />
                    {formik?.touched?.cityId && formik?.errors?.cityId ? (<div className='req-error'>{formik?.errors?.cityId}</div>) : null}
                  </div>

                  <div className='row '>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input type="text" className="form-control" id="name" placeholder="Enter period name" name='name' value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                      {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="noOfTrips" className="form-label">No. of Trips</label>
                      <input type="number" className="form-control" id="noOfTrips" placeholder="Enter period name" name='noOfTrips' value={formik?.values?.noOfTrips} onChange={formik?.handleChange} onBlur={formik?.handleBlur} onWheel={(e) => e.target.blur()}/>
                      {formik?.touched?.noOfTrips && formik?.errors?.noOfTrips ? (<div className='req-error'>{formik?.errors?.noOfTrips}</div>) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="duration" className="form-label">Duration</label>
                      <Select
                        id="duration"
                        name="duration"
                        options={durationOptions}
                        value={formik.values.duration}
                        onChange={(selectedOption) =>
                            formik.setFieldValue('duration', selectedOption)
                        }
                        onBlur={() => formik.setFieldTouched('duration', true)}
                      />
                      {formik.touched.duration && formik.errors.duration ? (
                        <div className='req-error'>{formik.errors.duration.label}</div>
                      ) : null}
                    </div>
                    {formik.values.duration && formik.values.duration.value === "period" && <>
                    <div className="mb-3 col">
                      <label htmlFor="from" className="form-label">From</label> <br />
                      <TimePicker
                        id="time-picker"
                        className={"w-100"}
                        clockClassName={"react-clock"}
                        format="h:m a"
                        isOpen={false}
                        clockIcon={null}
                        closeClock={true}
                        hourPlaceholder='hh'
                        minutePlaceholder='mm'
                        secondPlaceholder='ss'
                        value={formik?.values?.from}
                        name="from"
                        onChange={(ele) => { formik?.setFieldValue("from", ele) }}
                        onBlur={() => { formik?.setFieldTouched("from", true) }}
                      />
                      {formik?.touched?.from && formik?.errors?.from ? (<div className='req-error'>{formik?.errors?.from}</div>) : null}
                    </div>

                    <div className="mb-3 col ">
                      <label htmlFor="to" className="form-label">To</label><br />
                      <TimePicker
                        id="time-picker"
                        clockClassName={"react-clock"}
                        className={"w-100"}
                        format="h:m a"
                        isOpen={true}
                        clockIcon={null}
                        closeClock={false}
                        hourPlaceholder='hh'
                        minutePlaceholder='mm'
                        secondPlaceholder='ss'
                        name='to'
                        value={formik?.values?.to}
                        onBlur={(ele) => { formik?.setFieldTouched("to", true) }}
                        onChange={(ele) => formik?.setFieldValue("to", ele)}
                      />
                      {formik?.touched?.to && formik?.errors?.to ? (<div className='req-error'>{formik?.errors?.to}</div>) : null}
                    </div>
                    </>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="minimumRating" className="form-label">Minimum Rating</label>
                    <input type="number" className="form-control" id="minimumRating" placeholder="Enter Minimum Rating" name='minimumRating' value={formik?.values?.minimumRating} onChange={formik?.handleChange} onBlur={formik?.handleBlur} onWheel={(e) => e.target.blur()}/>
                    {formik?.touched?.minimumRating && formik?.errors?.minimumRating ? (<div className='req-error'>{formik?.errors?.minimumRating}</div>) : null}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="amount" className="form-label">Amount</label>
                    <input type="number" className="form-control" id="amount" placeholder="Enter Amount" name='amount' value={formik?.values?.amount} onChange={formik?.handleChange} onBlur={formik?.handleBlur} onWheel={(e) => e.target.blur()} />
                    {formik?.touched?.amount && formik?.errors?.amount ? (<div className='req-error'>{formik?.errors?.amount}</div>) : null}
                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="minRideChargesForBonus" className="form-label">Min ride charge for bonus</label>
                    <input type="number" className="form-control" id="minRideChargesForBonus" placeholder="Enter" name='minRideChargesForBonus' value={formik?.values?.minRideChargesForBonus} onChange={formik?.handleChange} onBlur={formik?.handleBlur} onWheel={(e) => e.target.blur()}/>
                    {formik?.touched?.minRideChargesForBonus && formik?.errors?.minRideChargesForBonus ? (<div className='req-error'>{formik?.errors?.minRideChargesForBonus}</div>) : null}
                  </div> */}
                  <button type="submit" disabled={addLoader} className="btn btn-primary">
                    {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddBonusFormModal