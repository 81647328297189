import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Logo from "./"
import { useLocation } from 'react-router-dom';
import { useAdminContext } from '../../context/adminContext';


export default function Sidebar() {
  const { adminDetail } = useAdminContext();

  const navigate = useNavigate();
  const location = useLocation()
  const token = localStorage.getItem("token")
  
  const handleLogout = () => {
    localStorage.removeItem("token");

    window.location.reload();
    navigate("/");
  }

  const path = location.pathname

  return (
    <>{token && <>
      <nav className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light" id="sidebar">
        <div className="container-fluid position-relative">
          {/* Toggler */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000" className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg>
          </button>
          {/* Brand */}
          <Link className="" to="/dashboard">
            <img src="/images/appLogo_new.png" className="navbar-brand-img mx-auto " alt="..." />
          </Link>

          {/* Collapse */}
          <div className="collapse navbar-collapse" id="sidebarCollapse">
            {/* Form */}

            <ul className="navbar-nav mb-5">
              {![ "webAdmin"].includes(adminDetail?.role) &&
                <li className={`nav-item ${path.includes("dashboard") ? "active" : ""}`}>
                <Link to="/dashboard" className="nav-link ms-3" role="button" aria-expanded="true" aria-controls="sidebarDashboards">
                  Dashboard
                </Link>

              </li>}

              {![ "webAdmin"].includes(adminDetail?.role) &&
                <li className={`nav-item ${path.includes("partners") ? "active" : ""}`}>
                <Link to="/partners?type=all" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                  Partners
                </Link>
              </li>}

              {!["admin1", "admin2", "webAdmin"].includes(adminDetail?.role) && <li className={`nav-item ${path.includes("customers") ? "active" : "" }`}>
                <Link to="/customers" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                  Customers
                </Link>
              </li>}

              {!["admin1", "admin2","webAdmin"].includes(adminDetail?.role) && <li className={`nav-item ${path.includes("withdrawal-request") ? "active" : ""}`}>
                <Link to="/withdrawal-request" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                  Withdrawal Requests
                </Link>
              </li>}

              {!["admin1", "admin2", "webAdmin"].includes(adminDetail?.role) && <li className={`nav-item ${path.includes("rides") ? "active" : ""}`}>
                <Link to="/rides" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                  Rides
                </Link>
              </li>}
              {!["admin1", "admin2", "webAdmin"].includes(adminDetail?.role) && <li className={`nav-item ${path.includes("referrals") ? "active" : ""}`}>
                <Link to="/referrals" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                  Reward/Referrals
                </Link>
              </li>}
              {adminDetail?.role === "superAdmin" && <><li className={`nav-item ${path.includes("global-settings") ? "active" : ""}`}>
                <Link to="/global-settings" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                  Global Settings
                </Link>
              </li>
              <li className={`nav-item ${path.includes("administrators") ? "active" : ""}`}>
                <Link to="/administrators" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                  Administrators
                </Link>
              </li>
                <li className={`nav-item ${path.includes("notifications") ? "active" : ""}`}>
                  <Link to="/notifications" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                    Notifications
                  </Link>
                </li>                
                
              </>}
              <li className={`nav-item ${path.includes("system-and-report") ? "active" : ""}`}>
                  <Link to="/system-and-report" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                  System and Report
                  </Link>
                </li>  
              {
               ( adminDetail?.role === "webAdmin" || adminDetail?.role === "superAdmin") && <>
                <li class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                        Web
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                      <div class="accordion-body">
                        {/* <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                        <ul className="navbar-nav mb-5">
                          <li className={`nav-item ${path.includes("terms-and-Conditions") ? "active" : ""}`}>
                            <Link to="/terms-and-Conditions" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                              Terms and Conditions
                            </Link>
                          </li>
                          <li className={`nav-item ${path.includes("privacy-policy") ? "active" : ""}`}>
                            <Link to="/privacy-policy" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                              Privacy Policy
                            </Link>
                          </li>
                          <li className={`nav-item ${path.includes("blog") ? "active" : ""}`}>
                            <Link to="/blog" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                              Blog
                            </Link>
                          </li>
                          <li className={`nav-item ${path.includes("help") ? "active" : ""}`}>
                            <Link to="/help" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                              Help
                            </Link>
                          </li>
                          <li className={`nav-item ${path.includes("careers") ? "active" : ""}`}>
                            <Link to="/careers" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                              Careers
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                </>
              }
            </ul>

            <div className="logout">
              <Link
                to="/"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalout"
                className="mb-4 mt-3 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-box-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                  />
                </svg>{" "}
                Log Out
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Log out popup code start here */}
      <div
        className="modal fade"
        id="exampleModalout"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body pe-0 ps-0 block-user">
              <div className="logout-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g transform="rotate(180 12 12)">
                    <g
                      id="feLogout0"
                      fill="none"
                      fillRule="evenodd"
                      stroke="none"
                      strokeWidth="1"
                    >
                      <g id="feLogout1" fill="currentColor">
                        <path
                          id="feLogout2"
                          d="M3 5c0-1.1.9-2 2-2h8v2H5v14h8v2H5c-1.1 0-2-.9-2-2V5Zm14.176 6L14.64 8.464l1.414-1.414l4.95 4.95l-4.95 4.95l-1.414-1.414L17.176 13H10.59v-2h6.586Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <h1 className="mb-0">Log Out?</h1>
              <p>Are you sure you want to log out?</p>
            </div>
            <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
              <button
                type="button"
                className="btn-custom w-auto"
                onClick={handleLogout}
              >
                LOG OUT
              </button>
              <button
                type="button"
                className="btn-custom btn-custom-ops w-auto text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </>}
    </>
  );
}
