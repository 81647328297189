import React, { useEffect, useState } from 'react'
import Spinwheel from '../Spinwheel';
import TimePicker from 'react-time-picker';
import ReactSelect from 'react-select';
import { cityByCountryApi, countryListApi } from '../../../utils/ApiEndpointFunctions';
import { useAdminContext } from '../../../context/adminContext';

function AddOrUpdateNotification({ formik, addLoader, modalId, countryOptions }) {
  const [cityOptions, setCityOptions] = useState([])
  const { adminDetail } = useAdminContext();

  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };


  const handleCountryChange = (country) => {

    setCityOptions([])
    formik.setFieldValue('countryId', country);
    formik.setFieldValue('cityId', {});

    filterCites(country.value)
  }

  const handleCityChange = (city) => {
    formik.setFieldValue('cityId', city)
  }

  useEffect(() => {
    adminDetail.countryId && filterCites(adminDetail.countryId)
  }, [])



  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>Notifications</h2>
                <form onSubmit={formik?.handleSubmit} >

                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">Country</label>
                    <ReactSelect options={countryOptions} value={formik?.values?.countryId} isDisabled={formik?.values?.id || adminDetail.role !== "superAdmin"} onChange={handleCountryChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                    {formik?.touched?.countryId && formik?.errors?.countryId ? (<div className='req-error'>{formik?.errors?.countryId}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">City</label>
                    <ReactSelect options={cityOptions} isDisabled={!formik?.values?.countryId || formik?.values?.id} value={formik?.values?.cityId?.label ? formik?.values?.cityId : null} onChange={handleCityChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
                    {formik?.touched?.cityId && formik?.errors?.cityId ? (<div className='req-error'>{formik?.errors?.cityId}</div>) : null}
                  </div>

                  <div className='row '>
                    <div className="mb-3">
                      <label htmlFor="title" className="form-label">Title</label>
                      <input type="text" className="form-control" id="title" placeholder="Enter period name" name='title' value={formik?.values?.title} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                      {formik?.touched?.title && formik?.errors?.title ? (<div className='req-error'>{formik?.errors?.title}</div>) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="body" className="form-label">Body</label>
                      <input type="text" className="form-control" id="body" placeholder="Enter period name" name='body' value={formik?.values?.body} onChange={formik?.handleChange} onBlur={formik?.handleBlur} onWheel={(e) => e.target.blur()} />
                      {formik?.touched?.body && formik?.errors?.body ? (<div className='req-error'>{formik?.errors?.body}</div>) : null}
                    </div>
                  </div>

                  <button type="submit" disabled={addLoader} className="btn btn-primary">
                    {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AddOrUpdateNotification