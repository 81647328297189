import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { termsAndConditionsListApi } from '../../../utils/ApiEndpointFunctions';
import { useAdminContext } from '../../../context/adminContext';
import TextEditor from '../../common/TextEditor';

function TermsAndConditions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("")
  const [typeList, setTypeList] = useState([])
  const {adminDetail} = useAdminContext();

  const getTermsAndConditionList = async () => {
    try {
      const res = await termsAndConditionsListApi()
      if (res.status === 200) {
        let list = res.data?.data
        setTypeList(list);
        if(searchParams?.get("tab")==null){
         let tab = list[0]?.value
         setActiveTab(tab);
         setSearchParams({ tab: tab });
      }else{
         setActiveTab(searchParams?.get("tab"));
        }
      }
    } catch (error) {
      console.log(error)
    }
  };


  const toggleTab = (tab) => {
    setActiveTab(tab);
    setSearchParams({ tab: tab });
  }

  useEffect(()=>{
   getTermsAndConditionList()
  },[])

  
  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className='d-flex justify-content-between'>
          {searchParams.get("tab") !== "appversion" && <ul className="nav nav-tabs">
            {typeList?.filter(ele => { return ele.value !== "privacyPolicy" })?.map((ele, index) => {
            return  <li className="nav-item">
              <button
                className={`nav-link ${(activeTab === ele?.value || searchParams.get("tab") == null) ? 'active' : ''}`}
                onClick={() => toggleTab(ele?.value)}
              >
               {ele?.label}
              </button>
            </li>
            })}
          </ul>}
        </div>
        {activeTab &&  <TextEditor type={activeTab}/>}
      </div>

     
    </div>
  )
}

export default TermsAndConditions