import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  adminChangePartnerApprovalStatus,
  adminGetAllDeletedUserList,
  adminGetAllUserList,
  adminUserChagneStatusApi,
  cityByCountryApi,
  countryListApi,
} from "../../../utils/ApiEndpointFunctions";
import { debounce } from "lodash";
import { useSearchParams } from 'react-router-dom';
import ConfirmationModal from "../../common/ConfirmationModal";
import DriverTable from "./driver/DriverTable";
import CategoryTable from "./driver/CategoryTable";
import RidesTable from "./driver/RidesTable";
import CuisineTable from "./restuarant/CuisineTable";
import OutletsTable from "./restuarant/OutletsTable";
import { useAdminContext } from "../../../context/adminContext";
import DefaultCategory from "./driver/DefaultCategory";
import DefaultCategoryPackage from "./package/DefaultCategoryPackage";
import DefaultCategoryRental from "./rentals/DefaultRentalCategory";
import MakeModel from "./rentals/MakeModel";
import RestaurantGlobalRate from "./restuarant/RestaurantGlobalRate";
import DriverRate from "./driver/DriverRate";
import { useFormik } from "formik";
import RentalCharges from "./rentals/RentalCharges";
import SalesAgentCharges from "./salesAgent/SalesAgentCharges";


export default function Users() {

  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [confirmStatus, setConfirmStatus] = useState({ status: "", userId: "" });
  const [enableDisable, setEnableDisable] = useState({ status: "", userId: "", type: "partnerUser" });
  const [searchParams, setSearchParams] = useSearchParams();
  const partnersRole = searchParams.get("type");
  const { limit, adminDetail } = useAdminContext()
  const EnabledDesabledModalId = "esdfsdanabledDesabledModalId"

  
  

  const activeTab = searchParams.get("tab");
  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])

  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleCityChange = (city) => {
    searchFormik.setFieldValue('cityId', city)
  }

  const handleCountryChange = (country) => {
    console.log(country, 'this is count')
    setCityOptions([])
    searchFormik.setFieldValue('countryId', country);
    searchFormik.setFieldValue('cityId', null);
    filterCites(country.value)
  }
  // console.log(adminDetail.countryId, adminDetail.countryName)
  const searchFormik = useFormik({
    initialValues: {
      countryId: adminDetail.role !== "superAdmin" && adminDetail.countryId ? { label: adminDetail.countryName, value: adminDetail.countryId } : null,
      cityId: null,
    },
    // enableReinitialize: true,
  })

  let currentPage = "";

  const handleChange = async (event) => {
    if (event.target.name === "searchField") {
      let search1 = event.target.value;
      setSearch(search1);
      setLoader(true);
      const res = await adminGetAllUserList(limit, 1, search1);
      const usersData = res?.data?.data?.findPartners;
      const totalLength = res?.data?.data?.totalLength;
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setUsers(usersData);
      setSelectedPage(1);
    }
    setLoader(false);
  };
  
  const debouncedOnChange = debounce(handleChange, 800);

  const handlePageClick = (event) => {
    currentPage = event.selected + 1;
    if (activeTab === "active" || activeTab == null) {
      getUsers(currentPage, limit);
    }
    else if (activeTab === "deleted") {
      getDeletedUsers(currentPage);
    };
    setSelectedPage(currentPage)
  };

  async function getUsers(page, limit) {
    try {
      setLoader(true);
      let role = partnersRole === "all" ? "" : partnersRole === "rentals" ? "hire" : partnersRole
      const res = await adminGetAllUserList(limit, page, search, role, searchFormik.values.countryId?.label, searchFormik.values.cityId?.label);
      const usersData = res?.data?.data?.findPartners;
      const totalLength = res?.data?.data?.totalLength;
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setUsers(usersData);
      setLoader(false);

    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  }

  async function getDeletedUsers(page) {
    try {
      setLoader(true);
      let role = partnersRole === "all" ? "" : partnersRole
      const res = await adminGetAllDeletedUserList(limit, page, search, role);
      const usersData = res?.data?.data;
      const totalLength = res?.data?.totalLength;
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setUsers(usersData);
      setLoader(false);


    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  }

  const handleChangeStatus = async () => {
    try {
      setLoader(true)
      await adminChangePartnerApprovalStatus(confirmStatus)
      getUsers(selectedPage, limit)
      document.getElementById('confirmationModal').click()
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something Went Wrong")
      setLoader(false)
      console.log(error)
    }
  }

  const handleDisableEnableStatus = async () => {
    try {
      setLoader(true)
      document.getElementById(EnabledDesabledModalId).click()
      await adminUserChagneStatusApi(enableDisable)
      getUsers(selectedPage, limit)
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  console.log(enableDisable)

  useEffect(() => {
    if (partnersRole === 'all' || activeTab === "driver" || activeTab === "restaurant" || activeTab === "vendor" || activeTab === "salesAgent") {
      getUsers(selectedPage, limit);
    }
  }, [activeTab, partnersRole, searchFormik.values])

  const tabs = {
    driver: [
      { key: 'driver', label: 'Driver' },
      adminDetail?.role !== "admin2" && { key: 'defaultCategory', label: 'Default Category' },
      adminDetail?.role !== "admin2" && { key: 'category', label: 'Category' },
       adminDetail?.role === "superAdmin" && { key: 'ride', label: 'Make/Model' },
      adminDetail?.role === "superAdmin" && { key: 'charges', label: "Charges"}
    ],
    package: [
      { key: 'category', label: 'Category' },
      // { key: 'defaultCategory', label: 'Default Category' },
    ],
    rentals: [
      { key: 'vendor', label: 'Vendor' },
      adminDetail?.role === "superAdmin" && { key: 'car', label: 'Car Category' },
      adminDetail?.role === "superAdmin" && { key: 'truck', label: 'Truck Category' },
      adminDetail?.role === "superAdmin" && { key: 'boat', label: 'Boat Category' },
      adminDetail?.role === "superAdmin" && { key: 'bus', label: 'Bus Category' },
      adminDetail?.role === "superAdmin" && { key: 'mini', label: 'Mini Category' },
      adminDetail?.role === "superAdmin" && { key: 'make-model', label: 'Make/Model' },
      adminDetail?.role === "superAdmin" && { key: 'charges', label: "Charges"}
    ],
    restaurant: [
      { key: 'restaurant', label: 'Restuarant' },
      { key: 'cuisine', label: 'Cuisines' },
      { key: 'category', label: 'Category' },
      adminDetail?.role === "superAdmin" && { key: 'global-rate', label: 'Global Rate'}
    ],
    salesAgent: [
      { key: 'salesAgent', label: 'Sales Agent' },
      adminDetail?.role === "superAdmin" && { key: 'charges', label: "Charges" }

    ],
  };

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* <BreadCrumb /> */}
            <div className="main-content">
              {/* HEADER */}
              <div className="row">
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <ul className="nav nav-tabs">

                      {tabs[partnersRole]?.map((tab) => (
                        <li key={tab.key} className="nav-item ms-3">
                          <button
                            className={`nav-link ${activeTab === tab.key ? 'active' : ''}`}
                            onClick={() => setSearchParams({ type: partnersRole, tab: tab.key })}
                          >
                            {tab.label}
                          </button>
                        </li>
                      ))}

                    </ul>
                  </div>

                  {(partnersRole === 'all' || activeTab === "driver" || activeTab === "restaurant" || activeTab === "vendor" || activeTab === "salesAgent") && (
                    <div className="card" >
                      <DriverTable
                        setConfirmStatus={setConfirmStatus}
                        enabledDesabledModalId={EnabledDesabledModalId}
                        setEnableDisable={setEnableDisable}
                        loader={loader}
                        users={users}
                        selectedPage={selectedPage}
                        page={page}
                        totalCount={totalCount}
                        handlePageClick={handlePageClick}
                        debouncedOnChange={debouncedOnChange}
                        getUsers={getUsers}
                        countryOptions={countryOptions}
                        cityOptions={cityOptions}
                        filterCites={filterCites}
                        filterCountry={filterCountry}
                        handleCityChange={handleCityChange}
                        handleCountryChange={handleCountryChange}
                        searchFormik={searchFormik}
                      />
                    </div>
                  )}

                  {partnersRole === "driver" &&
                    <div className="card">
                      {activeTab === "defaultCategory" && <DefaultCategory />}
                      {activeTab === "category" && <CategoryTable tableType="yiinoRide" />}
                      {activeTab === "ride" && adminDetail?.role === "superAdmin" && <RidesTable />}
                      {activeTab === "charges" && adminDetail?.role === "superAdmin" && <DriverRate />}
                    </div>
                  }

                  {partnersRole === "restaurant" &&
                    <div className="card">
                      {activeTab === "cuisine" && <CuisineTable />}
                      {activeTab === "category" && <OutletsTable />}
                      {activeTab === "global-rate" && adminDetail?.role === "superAdmin" && <RestaurantGlobalRate />}
                    </div>
                  }

                  {partnersRole === "package" &&
                    <div className="card">
                      {activeTab === "category" && <CategoryTable tableType={"yiinoPackage"} />}
                      {activeTab === "defaultCategory" && <DefaultCategoryPackage />}
                    </div>
                  }

                  {partnersRole === "rentals" &&
                    <div className="card">
                      {["car", "truck", "boat", "bus", "mini"].includes(activeTab) && <DefaultCategoryRental />}
                      {activeTab === "make-model" && adminDetail?.role === "superAdmin" && <MakeModel />}
                      {activeTab === "charges" && adminDetail?.role === "superAdmin" && <RentalCharges />}
                    </div>
                  }
                  {partnersRole === "salesAgent" &&
                    <div className="card">
                      {activeTab === "charges" && <SalesAgentCharges />}
                    </div>
                  }

                </>
              </div>
              <button
                className="d-none"
                id="confirmModalBtn"
                data-bs-toggle="modal"
                data-bs-target="#confirmationModal">
                openmodal
              </button>
              {<ConfirmationModal
                handleConfirm={handleChangeStatus}
                confirmStatus={confirmStatus}
              />}

              <ConfirmationModal
                handleConfirm={handleDisableEnableStatus}
                confirmStatus={enableDisable}
                modalId={EnabledDesabledModalId}
                message={<>Are you sure you want to
                  <br /> {enableDisable.status === 'approved' ? 'enable' : enableDisable.status === 'rejected' ? 'disable' : ""} this User?</>}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
