import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useAdminContext } from '../../../context/adminContext';
import { cityByCountryApi, countryListApi, getRidesListApi, getUserDetailsByIdApi } from '../../../utils/ApiEndpointFunctions';
import { capitalizeWords } from '../../../utils/HelperFuncitons';
import LimitPagination from '../../common/LimitPagination';
import RidesDetailModal from '../../common/Modal/RidesDetailModal';
import CommonTable from '../../common/Table/CommonTable'
import moment from 'moment';
import Spinwheel from '../../common/Spinwheel';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import ReactSelect from 'react-select';

function Rides({ userId, type }) {
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [selectedRide, setSelectedRide] = useState()
  const [userProfile, setUserProfile] = useState({})
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname?.includes("/customers") ? "profileDetails" : "rides");
  const rideDetailModalId = "rideDetailModal";
  // const limit = 10;
  const {limit, adminDetail} = useAdminContext();

   const [countryOptions, setCountryOptions] = useState([])
    const [cityOptions, setCityOptions] = useState([])
  
    const filterCites = async (countryId) => {
      try {
        const res = await cityByCountryApi("", countryId)
        if (res.status === 200) {
          let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
          setCityOptions(cityOption);
        }
  
      } catch (error) {
        console.log(error)
      }
    };
  
    const filterCountry = async (inputValue) => {
      try {
        const res = await countryListApi(inputValue)
        if (res.status === 200) {
          let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
          setCountryOptions(cityOption);
        }
      } catch (error) {
        console.log(error)
      }
    };
  
    const handleCityChange = (city) => {
      searchFormik.setFieldValue('cityId', city)
    }
  
    const handleCountryChange = (country) => {
      console.log(country, 'this is count')
      setCityOptions([])
      searchFormik.setFieldValue('countryId', country);
      searchFormik.setFieldValue('cityId', null);
      filterCites(country.value)
    }
  
    const searchFormik = useFormik({
      initialValues: {
        countryId: adminDetail.role !== "superAdmin" && adminDetail.countryId ? { label: adminDetail.countryName, value: adminDetail.countryId } : null,
        cityId: null,
      },
    })
  


  const rideStatus = (status, cancelledBy) => {
    return (
      status === "pending" || !status ? "Requested" :
        status === "accepted" ? "On route to pickup" :
          status === "arrived" ? "Arrived at pickup point" :
            status === "startRide" ? "In-progress" :
              status === "completed" ? "Completed" :
                status === "cancelled" ? `Cancelled ${cancelledBy ? "By " + capitalizeWords(cancelledBy) : ""} ` : "-")
  }

  const hireRequestStatus = (status, cancelledBy) => {
    return (
      !status ? "Requested" :
        status === "accepted" ? "Accepted" :
          status === "ongoing" ? "In-Progress" :
            status === "vehicleDelivered" ? "Vehicle Delivered To user" :
              status === "completed" ? "Completed" :
                status === "cancelled" ? `Cancelled ${cancelledBy ? "By " + capitalizeWords(cancelledBy) : ""} ` : "-")
  }

  const restaurantOrderStatus = (status, cancelledBy) => {
    return (
      status === "pending" ? "Requested" :
        status === "preparing" ? "Preparing" :
          status === "ready" ? "Ready" :
            status === "pickedUp" ? "Picked Up by Driver" :
              status === "delivered" ? "Delivered" :
                status === "rejected" ? "Rejected By Restaurant" :
                  status === "cancelled" ? `Cancelled ${cancelledBy ? "By " + capitalizeWords(cancelledBy) : ""} ` : "-")
  }

  const getAllrides = async (selectedPage, limit) => {
    try {
      if (activeTab === "profileDetails") return;
      setLoader(true)
      const res = await getRidesListApi(limit, selectedPage, search, activeTab, (type === "customerDetails" ? userId : ''), searchFormik?.values?.countryId?.label, searchFormik?.values?.cityId?.label)
      if (res.status === 200 || res.status === 201) {
        const usersData = res?.data?.data?.rides;
        const totalLength = res?.data?.data?.totalLength;
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setTableData(usersData);
        setLoader(false);
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    getAllrides(currentPage,limit)
    setSelectedPage(currentPage)

  };

  const baseHeaderData = [
    { name: "Id", className: "col-1", key: "id" },
    ...(type !== "customerDetails" ? [{ name: "Customer", className: "col-1", key: "customer" }] : []),
    { name: "Fare", className: "col-1", key: "charges" },
    { name: "Payment Mode", className: "col-1", key: "paymentMode" },
    { name: "Status", className: "col-1", key: "rideStatus" },
    { name: "Action", className: "col-1", key: "action" }
  ];
  
  const specificHeaderData = {
    rides: [
      { name: "Driver", className: "col-1", key: "driver" },
      { name: "Pickup", className: "col-1", key: "pickup" },
      { name: "DropOff", className: "col-1", key: "dropoff" }
    ],
    packageDelivery: [
      { name: "Receiver", className: "col-1", key: "receiver"},
      { name: "Driver", className: "col-1", key: "driver" },
      { name: "Pickup", className: "col-1", key: "pickup" },
      { name: "DropOff", className: "col-1", key: "dropoff" },
      { name: "Type", className: "col-1", key: "packageType" },
    ],
    hire: [
      { name: "Vendor", className: "col-1", key: "vendor" },
      { name: "Pickup", className: "col-1", key: "pickup" },
      { name: "DropOff", className: "col-1", key: "dropoff" }
    ],
    foodOrders: [
      { name: "Restaurant", className: "col-1", key: "restaurant" },
      { name: "Delivery Partner", className: "col-1", key: "deliveryPartner" }
    ]
  };
  
  const headerData = activeTab === "profileDetails" ? [] : [
    ...baseHeaderData?.slice?.(0, 2),
    ...(specificHeaderData?.[activeTab]),
    ...baseHeaderData?.slice?.(2)
  ];


  
  const RideTableData = tableData?.map?.((item) => {

    return ({
    id: item.id,
    customer: `${item?.user?.firstName ? capitalizeWords(item?.user?.firstName) : ""} ${item?.user?.lastName ? capitalizeWords(item?.user?.lastName) : ""}`,
    driver: item?.bookingDetail?.partnerUser?.partnerUserDetails?.[0]?.name,
    vendor: item?.bookingDetailsRental?.partnerUser?.partnerUserDetails?.[0]?.name,
    pickup: (
      <span title={item?.pickup}>{item?.pickup ? item?.pickup?.slice(0, 10) + (item?.pickup?.length > 10 ? '...' : "") : "-"}</span>
    ),
    dropoff: (
      <span title={item?.dropOff}>{item?.dropOff ? item?.dropOff?.slice(0, 10) + (item?.dropOff?.length > 10 ? '...' : "") : "-"}</span>
    ),
    receiver: item?.receiverName,
    restaurant: item?.restaurant?.name,
    deliveryPartner: item?.driverDetails?.name,
    charges: activeTab === "foodOrders" ? item?.totalAmount ?? "" : item?.charges ?? "",
    paymentMode: item?.paymentMode ? capitalizeWords(item?.paymentMode) : "-",
    packageType: item?.bookingType === "yiinoPackage" ? "Yiino Package" : "Yiino Food",
    rideStatus: activeTab === "hire" ? hireRequestStatus(item.isCancelled ? "cancelled" : item?.bookingDetailsRental?.status, (item.bookingDetailsRental?.role ?? "user"))
                : activeTab === "foodOrders" ? restaurantOrderStatus(item?.status, item?.cancelledBy)
                  : rideStatus(item.isCancelled ? "cancelled" : item?.bookingDetail?.status, item.bookingDetail?.role),
    action: (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          <li className="dropdown-item">
            <div
              data-bs-toggle="modal"
              data-bs-target={`#${rideDetailModalId}`}
              style={{ cursor: "pointer" }}
              onClick={() => { setSelectedRide(item) }}
            >
              View
            </div>
          </li>
        </ul>
      </div>
    )
  })})


  useEffect(() => {
    getAllrides(selectedPage,limit);
  }, [activeTab, searchFormik.values])

  const tabData = [
    { name: "Rides", tab: "rides" },
    { name: "Package Delivery", tab: "packageDelivery" },
    { name: "Hire/ Rental", tab: "hire" },
    { name: "Food Orders", tab: "foodOrders" },
  ]

  const handleChangeTab = (tab) => {
    if(loader) return;
    setSelectedPage(1)
    setTableData([])
    setTotalCount(null)
    setActiveTab(tab)
  }

  async function getUserDetails () {
    try {
      setLoader(true)
      const res = await getUserDetailsByIdApi(userId)
      if(res.status === 200) {
        console.log(res.data)
        setUserProfile(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }

    setLoader(false)
  }

  useEffect(() => {
    
    adminDetail.countryId && filterCites(adminDetail.countryId) 
    adminDetail.countryId || filterCountry();
    if (userId) getUserDetails()
  }, [])

  

  const profileData = [
    { name: "Email", data: userProfile.email, },
    { name: "Phone", data: userProfile.phone, },
    { name: "Alternate Phone", data: userProfile.alternatePhoneNumber, },
    { name: "Role", data: capitalizeWords(userProfile.role), },
    { name: "DOB", data: moment(userProfile.dob).format("LL")  },
    { name: "Minor", data: userProfile.isMinor },
    { name: "totalRides", data: userProfile.totalRides },   
    { name: "Gender", data: userProfile.gender, },
    { name: "City", data: userProfile.city, },
    { name: "Country", data: userProfile.country, },   
  ]

  return (

    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* <BreadCrumb /> */}
            <div className="main-content">
              {/* HEADER */}
              <div className="row">
                <>
                  <ul className="nav nav-tabs">

                    {location.pathname?.includes("/customers") && <button
                      className={`nav-link ${(activeTab === "profileDetails") ? 'active' : ''}`}
                      onClick={() => handleChangeTab("profileDetails")}
                    >
                      Profile details
                    </button>}

                    {tabData.map((el, index) => <li key={index} className="nav-item ms-3">
                      <button
                        className={`nav-link ${(activeTab === el.tab) ? 'active' : ''}`}
                        onClick={() => handleChangeTab(el.tab)}
                      >
                        {el.name}
                      </button>
                    </li>)}

                  </ul>
                 
                  {activeTab === "profileDetails" && location.pathname?.includes("/customers") ?

                    <div className="row card">
                      <div className="row mt-3">
                        <div className="user-details">
                          <table className="table table-md table-hover card-table">
                            <tbody>
                              {loader ? 
                                <tr>
                                  <td className='text-center'>
                                    <Spinwheel />
                                  </td>
                                </tr> :
                                profileData.map((ele, index) => (
                                  <tr key={index}>
                                    <td>{ele.name}:</td>
                                    <td>{ele.data}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                    : <>
                      <div className="card">
                        <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0 py-4">
                          <LimitPagination getData={getAllrides} />
                          <div className="d-flex gap-2 " style={{ display: "-webkit-inline-box" }}>

                            <div className="d-flex align-items-center gap-2">
                              <label htmlFor="city" className="form-label">Country</label>
                              <ReactSelect className='reactSelectMinWidth' isDisabled={adminDetail.role !== "superAdmin"} options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                              {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
                            </div>

                            <div className="d-flex align-items-center gap-2">
                              <label htmlFor="city" className="form-label">City</label>
                              <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
                              {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
                            </div>

                            <div className='d-flex align-items-center gap-2'>
                              <button
                                className="btn btn-primary"
                                onClick={() => { searchFormik.resetForm(); }}
                                disabled={!searchFormik?.values?.countryId}
                              >
                                Reset filter
                              </button>
                            </div>
                          </div>
                          
                        </div>
                      <CommonTable headers={headerData} data={RideTableData} loading={loader} />
                      </div>
                    </>}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>

      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={selectedPage - 1}
          />
        </div>
      ) : (
        ""
      )}

      <RidesDetailModal
        rideDetail={selectedRide}
        modalId={rideDetailModalId}
        activeTab={activeTab}
        rideStatus={rideStatus}
        hireRequestStatus={hireRequestStatus}
        restaurantOrderStatus={restaurantOrderStatus}
      />
    </>

  )
}

export default Rides