import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Link, useSearchParams } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import { S3_URL } from '../../../utils/BaseUrl';
import { capitalizeWords, dateFormat } from '../../../utils/HelperFuncitons';
import LimitPagination from '../../common/LimitPagination';
import ImageViewModal from '../../common/Modal/ImageViewModal';
import Spinwheel from '../../common/Spinwheel';
import CommonTable from '../../common/Table/CommonTable';
import ReactSelect from 'react-select';

function CustomerTable(props) {
  const { setConfirmStatus, getUsers, loader, users, selectedPage, page, totalCount, handlePageClick, debouncedOnChange, countryOptions, cityOptions, filterCites, filterCountry, handleCityChange, handleCountryChange, searchFormik } = props;
  
  const {limit, adminDetail} = useAdminContext();
  const imageViewModalId = "imageViewModalId"
  const [selectedImg, setSelectedImg] = useState("")
  const confirmApproveOrReject = (status, userId) => {
    setConfirmStatus({ status, userId })
    document.getElementById('confirmModalBtn').click()
  }

  const headerData = [
    { name: "S.No", className: "col-1", key: "index" },
    { name: "Name", className: "col-4", key: "name" },
    { name: "Email ID", className: "col-1", key: "email" },
    { name: "Contact", className: "col-1", key: "phone" },
    { name: "Role", className: "col-1", key: "role" },
    { name: "Parent No", className: "col-1", key: "alternatePhoneNumber" },
    { name: "City", className: "col-1", key: "city" },
    { name: "Status", className: "col-1", key: "isActive" },
    { name: "Joining Date", className: "col-1", key: "createdAt" },
    { name: "Action", className: "col-1", key: "action" },

  ]

  const tableData = users.map((item, index) => ({
    index: index+ 1,
    name: (
      <>
        <div className="avatar avatar-xs align-middle me-2">
          <img
            className="avatar-img rounded-circle"
            src={!item.profileImage ? "/images/profiledp.png" : `${S3_URL}/${item?.profileImage}`}
            alt="img"
            data-bs-toggle="modal"
            data-bs-target={`#${imageViewModalId}`}
            style={{ cursor: 'pointer' }}
            onClick={() => { setSelectedImg(!item.profileImage ? "/images/profiledp.png" : `${S3_URL}/${item?.profileImage}`) }}
          />
        </div>
        <Link className="text-primary" to={`/customers/${item.id}`} >{item?.firstName ? capitalizeWords(`${item?.firstName} ${item?.lastName}`) : "-"}</Link>
      </>),
    email: item?.email || "-",
    phone: item?.phone || "-",
    role: item?.role ? capitalizeWords(item?.role) : "-",
    alternatePhoneNumber: item?.alternatePhoneNumber || "-",
    city: item?.city || "-",
    createdAt: item?.createdAt ? dateFormat(item?.createdAt) : "-",
   
    isActive: item?.isActive ? "Enabled" : "Disabled",

    action: (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          {item?.isActive === false && <li
            onClick={() =>
              confirmApproveOrReject('approved', item?.id)
            }
          >
            <a className="dropdown-item" href="#">
              Enable
            </a>
          </li>}

          {item?.isActive === true && <li
            onClick={() =>
              confirmApproveOrReject('rejected', item?.id)
            }
          >
            <a className="dropdown-item" href="#">
              Disable
            </a>
          </li>}
        </ul>
      </div>
    )
  }))

  useEffect(() => {
    adminDetail.countryId && filterCites(adminDetail.countryId)
    adminDetail.countryId || filterCountry();
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
        <LimitPagination getData={getUsers} />
        <div style={{ display: "-webkit-inline-box" }}>
          <div className="position-relative my-3 d-flex gap-2">

            <div className="d-flex align-items-center gap-2">
              <label htmlFor="city" className="form-label">Country</label>
              <ReactSelect className='reactSelectMinWidth' isDisabled={adminDetail.role !== "superAdmin"} options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
              {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
            </div>

            <div className="d-flex align-items-center gap-2">
              <label htmlFor="city" className="form-label">City</label>
              <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
              {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
            </div>

            <div className='d-flex align-items-center gap-2'>
              <button
                className="btn btn-primary"
                onClick={() => { searchFormik.resetForm(); }}
                disabled={!searchFormik?.values?.countryId}
              >
                Reset filter
              </button>
            </div>

            <input
              className="form-search me-3"
              type="search"
              name="searchField"
              id=""
              placeholder="Search"
              onChange={debouncedOnChange}
            // value = {search}
            />

          </div>
        </div>
      </div>
      <CommonTable headers={headerData} data={tableData} loading={loader} />
      <ImageViewModal imgUrl={selectedImg} modalId={imageViewModalId} />

      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={selectedPage - 1}
          />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default CustomerTable