import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { adminDashboardDetailsApi, cityByCountryApi, countryListApi, getUserDetailsByIdApi } from "../../../utils/ApiEndpointFunctions";
import Spinwheel from "../../common/Spinwheel";
import CommonCard from "../../common/Cards/CommonCard";
import TopPerformerCard from "../../common/Cards/TopPerformerCard";
import { useAdminContext } from "../../../context/adminContext";
import { useFormik } from 'formik';
import ReactSelect from 'react-select';

export default function ViewReport({ userId, type }) {
   const {limit, adminDetail} = useAdminContext();
     const [userProfile, setUserProfile] = useState({})
  const [loader, setLoader] = useState(true)
  const [details, setDetails] = useState({})


     const [countryOptions, setCountryOptions] = useState([])
      const [cityOptions, setCityOptions] = useState([])
    
      const filterCites = async (countryId) => {
        try {
          const res = await cityByCountryApi("", countryId)
          if (res.status === 200) {
            let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
            setCityOptions(cityOption);
          }
    
        } catch (error) {
          console.log(error)
        }
      };
    
      const filterCountry = async (inputValue) => {
        try {
          const res = await countryListApi(inputValue)
          if (res.status === 200) {
            let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
            setCountryOptions(cityOption);
          }
        } catch (error) {
          console.log(error)
        }
      };
    
      const handleCityChange = (city) => {
        searchFormik.setFieldValue('cityId', city)
      }
    
      const handleCountryChange = (country) => {
        console.log(country, 'this is count')
        setCityOptions([])
        searchFormik.setFieldValue('countryId', country);
        searchFormik.setFieldValue('cityId', null);
        filterCites(country.value)
      }
    
      const searchFormik = useFormik({
        initialValues: {
          countryId: adminDetail?.role !== "superAdmin" && adminDetail?.countryId ? { label: adminDetail?.countryName, value: adminDetail?.countryId } : null,
          cityId: null,
        },
      })
   
     useEffect(() => {
       
       adminDetail?.countryId && filterCites(adminDetail.countryId) 
       adminDetail?.countryId || filterCountry();
     }, [])

  const getDashboardDetails = async (countryName="",cityName="") => {
    try {
      setLoader(true);
      const res = await adminDashboardDetailsApi(countryName,cityName);
      setDetails(res?.data?.data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      
      toast.error(error.message || "Something went wrong");
      setLoader(false);
    }
  }

  useEffect(() => {
   const {countryId,cityId} = searchFormik?.values
    getDashboardDetails(countryId?.label,cityId?.label);
  }, [searchFormik?.values])

  const toFixedNumber = (num) => {
    if (num) {
      return Number(num).toFixed(2)
    }
    return 0.00
  }

  console.log("search",loader);
  

  return (
    <>
      <div className="main-content">

        <div className="container-fluid">
          {
            loader ? <Spinwheel /> :
              <>
                 <div className="card">
                        <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0 py-4">
                          <div className="d-flex gap-2 justify-content-end align-items-end w-100 px-5" style={{ display: "-webkit-inline-box" }}>
                              <div className="d-flex gap-2">
                            <div className="d-flex align-items-center gap-2">
                              <label htmlFor="city" className="form-label">Country</label>
                              <ReactSelect className='reactSelectMinWidth' isDisabled={adminDetail.role !== "superAdmin"} options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                              {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
                            </div>

                            <div className="d-flex align-items-center gap-2">
                              <label htmlFor="city" className="form-label">City</label>
                              <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
                              {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
                            </div>

                            <div className='d-flex align-items-center gap-2'>
                              <button
                                className="btn btn-primary"
                                onClick={() => { searchFormik.resetForm(); }}
                                disabled={!searchFormik?.values?.countryId}
                              >
                                Reset filter
                              </button>
                            </div>
                              </div>
                          </div>
                          
                        </div>
                        <div className=" mt-4">
                  {/* Rides (Trips) Section */}
                  <div className="card card-body mt-2">
                    <div className="p-4">
                      <h3>Rides (Trips)</h3>
                      <div className="row row-cols-1 row-cols-md-2">
                        <div className="col-md-12 col-lg-3 border-primary border rounded-3">
                          <div className="row">
                            <CommonCard title={"Today"} value={details?.rideDetails?.[0]?.rideBookingThisDay || 0} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"This Week"} value={details?.rideDetails?.[0]?.rideBookingThisWeek || 0} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"This Month"} value={details?.rideDetails?.[0]?.rideBookingThisMonth || 0} icon={<i class="bi bi-activity"></i>} />
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-9">
                          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-3">
                            <CommonCard title={"Total Rides Requested"} value={details?.rideDetails?.[0]?.rideBookingTotal || 0} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"In-Progress"} value={details?.rideDetails?.[0]?.inProgressRides || 0} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Completed"} value={details?.rideDetails?.[0]?.completedRides || 0} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Cancelled"} value={details?.rideDetails?.[0]?.cancelledRides || 0} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Cancelled"} isValueBold={false} value={`${details?.rideDetails?.[0]?.cancelledRides || 0} (Riders: ${details?.rideDetails?.[0]?.userCancelledRide || 0} | Drivers: ${details?.rideDetails?.[0]?.driverCancelledRide || 0})`} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Expired Requests"} value={details?.rideDetails?.[0]?.expired || 0} icon={<i class="bi bi-activity"></i>} />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* Payment Section */}
                  <div className="card card-body mt-2">
                    <div className="p-4">
                      <h3>Total Payment</h3>

                      {/*  ride */}
                      <div className="border-primary border rounded-3 p-4 mt-2">
                        <h3>Ride</h3>
                        <div className="row row-cols-1 row-cols-md-2">
                          <div className="col-md-12 col-lg-3 border-primary border rounded-3">
                            <div className="row">
                              <CommonCard title={"Today"} value={toFixedNumber(details?.rideDetails?.[0]?.rideTodayPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"This Week"} value={toFixedNumber(details?.rideDetails?.[0]?.rideWeekPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"This Month"} value={toFixedNumber(details?.rideDetails?.[0]?.rideMonthPayment)} icon={<i class="bi bi-activity"></i>} />
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-9">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-3">
                              <CommonCard title={"Total"} value={toFixedNumber(details?.rideDetails?.[0]?.rideTotalPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"Cash"} value={toFixedNumber(details?.rideDetails?.[0]?.rideTotalCashPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"Mobile"} value={toFixedNumber(details?.rideDetails?.[0]?.rideTotalMobilePayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"Wallet"} value={toFixedNumber(details?.rideDetails?.[0]?.rideTotalWalletPayment)} icon={<i class="bi bi-activity"></i>} />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* food */}
                      <div className="border-primary border rounded-3 p-4 mt-2">
                        <h3>Food</h3>
                        <div className="row row-cols-1 row-cols-md-2">
                          <div className="col-md-12 col-lg-3 border-primary border rounded-3">
                            <div className="row">
                              <CommonCard title={"Today"} value={toFixedNumber(details?.rideDetails?.[0]?.foodTodayPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"This Week"} value={toFixedNumber(details?.rideDetails?.[0]?.foodWeekPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"This Month"} value={toFixedNumber(details?.rideDetails?.[0]?.foodMonthPayment)} icon={<i class="bi bi-activity"></i>} />
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-9">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-3">
                              <CommonCard title={"Total"} value={toFixedNumber(details?.rideDetails?.[0]?.foodTotalPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"Cash"} value={toFixedNumber(details?.rideDetails?.[0]?.foodTotalCashPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"Mobile"} value={toFixedNumber(details?.rideDetails?.[0]?.foodTotalMobilePayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"Wallet"} value={toFixedNumber(details?.rideDetails?.[0]?.foodTotalWalletPayment)} icon={<i class="bi bi-activity"></i>} />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* package */}
                      <div className="border-primary border rounded-3 p-4 mt-2">
                        <h3>Packages</h3>
                        <div className="row row-cols-1 row-cols-md-2">
                          <div className="col-md-12 col-lg-3 border-primary border rounded-3">
                            <div className="row">
                              <CommonCard title={"Today"} value={toFixedNumber(details?.rideDetails?.[0]?.packageTodayPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"This Week"} value={toFixedNumber(details?.rideDetails?.[0]?.packageWeekPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"This Month"} value={toFixedNumber(details?.rideDetails?.[0]?.packageMonthPayment)} icon={<i class="bi bi-activity"></i>} />
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-9">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-3">
                              <CommonCard title={"Total"} value={toFixedNumber(details?.rideDetails?.[0]?.packageTotalPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"Cash"} value={toFixedNumber(details?.rideDetails?.[0]?.packageTotalCashPayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"Mobile"} value={toFixedNumber(details?.rideDetails?.[0]?.packageTotalMobilePayment)} icon={<i class="bi bi-activity"></i>} />
                              <CommonCard title={"Wallet"} value={toFixedNumber(details?.rideDetails?.[0]?.packageTotalMobilePayment)} icon={<i class="bi bi-activity"></i>} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* driver */}
                  <div className="card card-body mt-2">
                    <div className="p-4">
                      <h3>Drivers</h3>
                      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-3">
                        <CommonCard title={"Total Drivers"} value={details?.driverDetails?.[0]?.totalDriver || 0} icon={<i class="bi bi-activity"></i>} />
                        <CommonCard title={"Verified"} value={details?.driverDetails?.[0]?.approvedDriver || 0} icon={<i class="bi bi-activity"></i>} />
                        <CommonCard title={"Unverified"} value={details?.driverDetails?.[0]?.rejectedDriver || 0} icon={<i class="bi bi-activity"></i>} />
                        <CommonCard title={"Online"} value={details?.driverDetails?.[0]?.OnlineDriver || 0} icon={<i class="bi bi-activity"></i>} />
                        <CommonCard title={"Offline"} value={`${details?.driverDetails?.[0]?.OfflineDriver || 0}`} icon={<i class="bi bi-activity"></i>} />
                        <CommonCard title={"OnSubscription"} value={details?.driverDetails?.[0]?.OnSubscriptionDriver || 0} icon={<i class="bi bi-activity"></i>} />
                        <CommonCard title={"OnCommission"} value={details?.driverDetails?.[0]?.OnCommissionDriver || 0} icon={<i class="bi bi-activity"></i>} />
                      </div>

                    </div>
                  </div>
                  {/* top PerformancePeriod */}
                  <div className="card card-body mt-2">
                    <div className="p-4">
                      <h3>Top Performing Drivers</h3>
                      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 mt-3">
                        <div className="p-2">
                          <div className="border-primary border rounded-3 p-4 mt-2">
                            <h3>Daily</h3>
                            {details?.topPerformarDriver?.filter(ele => ele?.PerformancePeriod?.toLowerCase() == "daily")?.sort((a, b) => Number(a?.rank) - Number(b?.rank))?.map(ele => <>
                              <TopPerformerCard title={ele?.driverName} value={`Trips: ${ele?.totalTrips} | Amount: ${toFixedNumber(ele?.totalEarnings)}`} icon={ele?.rank} />
                            </>)}
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="border-primary border rounded-3 p-4 mt-2">
                            <h3>Weekly</h3>
                            {details?.topPerformarDriver?.filter(ele => ele?.PerformancePeriod?.toLowerCase() == "weekly")?.sort((a, b) => Number(a?.rank) - Number(b?.rank))?.map(ele => <>
                              <TopPerformerCard title={ele?.driverName} value={`Trips: ${ele?.totalTrips} | Amount: ${toFixedNumber(ele?.totalEarnings)}`} icon={ele?.rank} />
                            </>)}
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="border-primary border rounded-3 p-4 mt-2">
                            <h3>Monthly</h3>
                            {details?.topPerformarDriver?.filter(ele => ele?.PerformancePeriod?.toLowerCase() == "monthly")?.sort((a, b) => Number(a?.rank) - Number(b?.rank))?.map(ele => <>
                              <TopPerformerCard title={ele?.driverName} value={`Trips: ${ele?.totalTrips} | Amount: ${toFixedNumber(ele?.totalEarnings)}`} icon={ele?.rank} />
                            </>)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* user reward / referrals */}
                  <div className="card card-body mt-2">
                    <div className="p-4">
                      <h3>Rewards/Referrals</h3>
                      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-3">
                        <div className="p-2">
                          <div className="border-primary border rounded-3 p-4 mt-2">
                            <h3>User Rewards</h3>
                            <CommonCard title={"Today"} value={toFixedNumber(details?.userRewardAndReferral[0]?.dailyrewardAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Weekly"} value={toFixedNumber(details?.userRewardAndReferral[0]?.weekRewardAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Monthly"} value={toFixedNumber(details?.userRewardAndReferral[0]?.montlyRewardAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Total"} value={toFixedNumber(details?.userRewardAndReferral[0]?.totalRewardAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Expired"} value={toFixedNumber(details?.userRewardAndReferral[0]?.expiredRewardAmt)} icon={<i class="bi bi-activity"></i>} />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="border-primary border rounded-3 p-4 mt-2">
                            <h3>User Referrals</h3>
                            <CommonCard title={"Today"} value={toFixedNumber(details?.userRewardAndReferral[0]?.dailyReferralAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Weekly"} value={toFixedNumber(details?.userRewardAndReferral[0]?.weekReferralAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Monthly"} value={toFixedNumber(details?.userRewardAndReferral[0]?.montlyReferralAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Total"} value={toFixedNumber(details?.userRewardAndReferral[0]?.totalReferralAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Expired"} value={toFixedNumber(details?.userRewardAndReferral[0]?.expiredReferralAmt)} icon={<i class="bi bi-activity"></i>} />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="border-primary border rounded-3 p-4 mt-2">
                            <h3>Partners Rewards</h3>
                            <CommonCard title={"Today"} value={toFixedNumber(details?.partnerRewardAndReferral[0]?.dailyrewardAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Weekly"} value={toFixedNumber(details?.partnerRewardAndReferral[0]?.weekRewardAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Monthly"} value={toFixedNumber(details?.partnerRewardAndReferral[0]?.montlyRewardAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Total"} value={toFixedNumber(details?.partnerRewardAndReferral[0]?.totalrewardAmt)} icon={<i class="bi bi-activity"></i>} />
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="border-primary border rounded-3 p-4 mt-2">
                            <h3>Partners Referrals</h3>
                            <CommonCard title={"Today"} value={toFixedNumber(details?.partnerRewardAndReferral[0]?.dailyReferralAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Weekly"} value={toFixedNumber(details?.partnerRewardAndReferral[0]?.weekReferralAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Monthly"} value={toFixedNumber(details?.partnerRewardAndReferral[0]?.montlyReferralAmt)} icon={<i class="bi bi-activity"></i>} />
                            <CommonCard title={"Total"} value={toFixedNumber(details?.partnerRewardAndReferral[0]?.totalReferralAmt)} icon={<i class="bi bi-activity"></i>} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                      </div>
      
              </>
          }


        </div>
      </div>
    </>
  );
}
