import React, { useMemo, useRef, useState } from 'react'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { useFormik } from 'formik';
// import { pageManagementIntialValue, pageMangagementValidationSchema } from '../../common/Validation';
import Spinwheel from './Spinwheel';
import { set } from 'lodash';
import { addOrUpdateTermAndConditionsApi, getTermsAndConditonsApi } from '../../utils/ApiEndpointFunctions';
import { termAndConditonsInitailValue, termAndConditonsValidationSchema } from './Validation';


function TextEditor({type}) {
  const [pageData, setPageData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(true)  
  const [addPageLoader, setAddPageLoader] = useState(false)

  const editor = useRef(null);

  const config = useMemo(() => {
    return {
      disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
      removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
      readonly: false,
      placeholder: 'Start writing...',
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      enter: "br",
      hidePoweredByJodit: true
    }
  }, []
  );

  const getPages = async () => {
   try {
      setLoader(true)
      setIsEdit(false)
      const res = await getTermsAndConditonsApi(type)
      if(res?.status==200){
         setPageData(res.data?.data)
         formik.setFieldValue("content",res?.data?.data?.content || "")
      }
      setLoader(false)
   } catch (error) {
      console.log(error)
   }
  }


  const handleSubmit = async (values) => {
    try {
      setAddPageLoader(true)

      const res = await addOrUpdateTermAndConditionsApi({...values,type,id:pageData?.id})
      if (res?.status === 200) {
        toast.success(res.data?.message)
        setIsEdit(false)
        // setPageData(res.data?.data)
      }
    } catch (error) {
      toast.error(error.message)
    }
    setAddPageLoader(false)

  }

  const formik = useFormik({
    initialValues: termAndConditonsInitailValue,
    onSubmit: handleSubmit,
    validationSchema: termAndConditonsValidationSchema
  })


  useEffect(()=>{
   if(type){
      getPages(type)
   }
  },[type])

  console.log("pasdf",pageData,type);
  

  return (
    <>
      {/*<Sidebar />*/}
      <div key={type + "sdfsd"} className="main-content vh-100 vh_md-auto overflow-y-a">
        <div className="container-fluid">
          <div className="row">
            <hr />

            <div className="card p-3 shadow">

              <div className="tab-content" id="nav-tabContent">
              <div className={`tab-pane fade active show`}  role="tabpanel" aria-labelledby={`nav-tab-1`}>
                      {loader ? <div className='text-center'><Spinwheel /></div> :
                        isEdit || !pageData?.content ?
                          <div>
                            <div>
                              <JoditEditor
                              key={type}
                                ref={editor}
                                value={formik.values?.content || ""}
                              //   config={config}
                                onBlur={(e) => {
                                  formik.setFieldTouched("content", true)
                                  e === "<p><br></p>" ? formik.setFieldValue("content", "") : formik.setFieldValue("content", e);
                                }}
                                onChange={(e) => { }}
                              />
                            </div>
                            <div className='mt-3'>
                              <button type="button" className="btn-custom w-auto" onClick={(e) => { formik.handleSubmit() }}  >{addPageLoader ? <Spinwheel sizeClass="spin-small"/> : "Submit"}</button>
                              {pageData?.content && <button type="button" className="btn-custom w-auto ms-3" onClick={(e) => { setIsEdit(false) }}  >Cancel</button>}
                            </div>
                          </div>
                          :
                          <div>
                            <div>
                              <p dangerouslySetInnerHTML={{ __html: pageData?.content }}></p>
                            </div>
                            <div className='mt-3'>
                              <button type="button" className="btn-custom w-auto" onClick={(e) => { setIsEdit(true) }}  >Edit</button>
                            </div>
                          </div>
                      }
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default TextEditor