import React, { useEffect, useState } from 'react'
import CommonTable from '../../common/Table/CommonTable'
import { useFormik } from 'formik';
import { addOrUpdateBonus,  addOrUpdateDiscountDetailsApi,  bonusListApi, cityByCountryApi, countryListApi, deleteDiscountApi, getDiscountListApi, updateBonusStatus, updateDiscountStatusApi } from '../../../utils/ApiEndpointFunctions';
import ReactSelect from 'react-select';
import { bonusInitialValue, bonusValidationSchema, discountInitialValue, discountValidationSchema } from '../../common/Validation';
import { capitalizeWords } from '../../../utils/HelperFuncitons';
import toast from 'react-hot-toast';
import DeletedModal from '../../common/Modal/DeletedModal';
import AddDiscountFormModal from '../../common/Modal/AddDiscountFormModal';
import { useAdminContext } from '../../../context/adminContext';

function Discount() {
  
  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const discountModalId = "discountModalId";
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [addLoader, setAddLoader] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [tableLoader, setTableLoader] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const deleteModalId = "bonusDeleteModal"
  const {adminDetail} = useAdminContext();


  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getDiscountList = async () => {
    try {
      setTableLoader(true)
      const res = await getDiscountListApi(searchFormik.values?.countryId?.value, searchFormik.values?.cityId?.value)
      if (res.status === 200) {
     
        setTableData(res.data?.data ? res.data?.data : [])
      }
    } catch (error) {
      console.log(error)
    }
    setTableLoader(false)
  }


  
  const deleteDiscount = async () => {
    setAddLoader(true)
    try {
      const res = await deleteDiscountApi({ id: selectedId.id, status: false })
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getDiscountList();
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const updatingValueHandler = (value) => {
    if (value?.duration) {
      value.duration = {
        label: capitalizeWords(value?.duration),
        value: value?.duration
      };
    }
    if (!value?.from || !value?.to) {
      value.from = ""
      value.to = ""
    }
    const updateObj = { ...value, cityId: { label: value.cityName, value: value.cityId }, countryId: { label: value.countryName, value: value.countryId } }
    formik.setValues(updateObj);
  };

 


  
  const handleSubmit = async (values) => {
    try {
      let payload = JSON.parse(JSON.stringify(values));
      
      setAddLoader(true)
   
      payload.countryId = payload?.countryId?.value;
      payload.cityId = payload?.cityId?.value;

      console.log(payload);
       
      const res = await addOrUpdateDiscountDetailsApi(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        getDiscountList();
        document.getElementById(discountModalId).click();
        formik.resetForm();
      }

    } catch (error) {
      console.log(error);
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const formik = useFormik({
    initialValues: { discountInitialValue, countryId: adminDetail.role !== "superAdmin" && adminDetail.countryId ? { label: adminDetail.countryName, value: adminDetail.countryId } : null, },
    onSubmit: handleSubmit,
    validationSchema: discountValidationSchema
  }) 

  const handleCityChange = (city) => {
    searchFormik.setFieldValue('cityId', city)
  }

  const handleCountryChange = (country) => {
    console.log(country, 'this is count')
    setCityOptions([])
    searchFormik.setFieldValue('countryId', country);
    searchFormik.setFieldValue('cityId', null);
    filterCites(country.value)
  }

  const handleDiscountEnabling = async (ele, index) => {
    try {
      setStatusLoading(true)
      const res = await updateDiscountStatusApi({ id: ele.id, status: !ele.isEnabled })
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        tableData[index].isEnabled = !ele.isEnabled;
        // getDiscountList();
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setStatusLoading(false)
  }

  const searchFormik = useFormik({
    initialValues: {
      countryId: adminDetail.role !== "superAdmin" && adminDetail.countryId ? { label: adminDetail.countryName, value: adminDetail.countryId } : null,
      cityId: null,
    },
  })

  useEffect(() => {
    adminDetail.countryId && filterCites(adminDetail.countryId)
    adminDetail.countryId || filterCountry();  
  }, [])

  useEffect(() => {
    getDiscountList()
  }, [searchFormik.values])


  

  const tableHeaderData = [
    { name: "S.No.", className: "col", key: "index" },
    { name: "Place", className: "col", key: "place" },
    { name: "Discount", className: "col", key: "discount" },
    { name: "No Of Trips", className: "col", key: "noOfTrips" },
    { name: "Enable", className: "col", key: "enable" },
   
    { name: "", className: "col-1", key: "action" }
  ]

  const tableRowData = tableData.map((ele, index) => {
    return {
      index: index + 1,
      place: <div className='text-primary' >{ele?.cityName} | {ele?.countryName}</div>,
      discount: ele?.discount || "-",
      noOfTrips: ele?.noOfTrips || "-",
      enable: (
        <div className="form-check form-switch ">
          <input className="form-check-input" type="checkbox" checked={ele?.isEnabled} disabled={statusLoading} role="switch" id="flexSwitchCheckDefault" onChange={() => handleDiscountEnabling(ele, index)} />
        </div>
      ),
      action: (<div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          <li className="dropdown-item">
            <div
              data-bs-toggle="modal"
              data-bs-target={`#${discountModalId}`}
              onClick={() => { updatingValueHandler(ele) }}
              style={{ cursor: "pointer" }}
            >
              Edit
            </div>
          </li>

          <li className="dropdown-item" >
            <div
              data-bs-toggle="modal"
              data-bs-target={`#${deleteModalId}`}
              onClick={() => { setSelectedId({ id: ele?.id, countryId: ele.countryId, cityId: ele.cityId }) }}
              style={{ cursor: "pointer" }}
            >
              Delete
            </div>
          </li>
        </ul>
      </div>)
    }
  })

  return (
    <>
      <div className='card'>
        <div className="card-header">
          <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
            <h1>Discount</h1>
            <div style={{ display: "-webkit-inline-box" }}>
              <div className="d-flex gap-2 justify-content-end align-items-center">
                <div className="d-flex align-items-center gap-2">
                  <label htmlFor="city" className="form-label">Country</label>
                  <ReactSelect className='reactSelectMinWidth' isDisabled={adminDetail.role !== "superAdmin"} options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                  {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
                </div>

                <div className="d-flex align-items-center gap-2">
                  <label htmlFor="city" className="form-label">City</label>
                  <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
                  {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
                </div>
                <div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => { searchFormik.resetForm(); }}
                      disabled={!searchFormik?.values?.countryId}
                    >
                      Reset filter
                    </button>
                  </div>
                </div>
               
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target={`#${discountModalId}`}
                  onClick={formik.resetForm}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className="card-body">
          <CommonTable headers={tableHeaderData} data={tableRowData} loading={tableLoader} />
        </div>
      </div>


      <AddDiscountFormModal modalId={discountModalId} addLoader={addLoader} formik={formik} countryOptions={countryOptions} />
      <DeletedModal
        modalId={deleteModalId}
        message={"this Discount"}
        deletedHandler={deleteDiscount}
        loader={addLoader}
      />
      
    </>)
}

export default Discount