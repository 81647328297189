import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useAdminContext } from '../../../../context/adminContext';
import { updateRestaurantFeeOnOffApi } from '../../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import {  globalRestaurantServiceFeeInitialValues, globalRestaurantServiceFeeValidationSchema } from '../../../common/Validation';

function RestaurantGlobalRate() {

  const [submitLoader, setSubmitLoader] = useState(false);
  const { adminDetail, setAdminDetail } = useAdminContext()


  const globalRateFormik = useFormik({
    initialValues: { ...globalRestaurantServiceFeeInitialValues, restaurentServiceFeeOnOff: adminDetail?.adminConfigurationModel?.restaurant?.restaurentServiceFeeOnOff, retaurantServiceFee: adminDetail?.adminConfigurationModel?.restaurant?.retaurantServiceFee },
    validationSchema: globalRestaurantServiceFeeValidationSchema,
    onSubmit: async (values) => {
      setSubmitLoader(true)
      try {
        const res = await updateRestaurantFeeOnOffApi(values)
        toast.success(res.data?.message)
        setAdminDetail({ ...adminDetail, adminConfigurationModel: res.data?.data })
      } catch (error) {
        toast.error(error?.response?.data?.message || "Something Went Wrong")
      }
      setSubmitLoader(false)
    }
  });

  console.log(globalRateFormik.values)


  return (
    <div className="">
      <div className="card-header" style={{ backgroundColor: "var(--color-card-bg)" }}>
        
      </div>
      <div className="card-body" >
        <div className='p-5'>
          <form onSubmit={globalRateFormik.handleSubmit}>
            <div className="form-group row row-cols-4">
              <div className='d-flex gap-3'>
                <label htmlFor='retaurantServiceFee' className=" col-form-label">Service Fee (%)</label>
                <div className="">
                  <input type="text" className="form-control" id='retaurantServiceFee' name='retaurantServiceFee' placeholder="0" onChange={globalRateFormik.handleChange} onBlur={globalRateFormik.handleBlur} value={globalRateFormik.values.retaurantServiceFee} />
                  {globalRateFormik.touched?.retaurantServiceFee && globalRateFormik.errors?.retaurantServiceFee ? (<div className='req-error'>{globalRateFormik?.errors?.retaurantServiceFee}</div>) : null}
                </div>
              </div>
              

              <div className='d-flex align-items-center gap-3'>
                <label htmlFor='restaurentServiceFeeOnOff' className=" col-form-label">Service fee (on/off)</label>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" role="switch" checked={globalRateFormik?.values?.restaurentServiceFeeOnOff} id="restaurentServiceFeeOnOff" onChange={() => globalRateFormik?.setFieldValue("restaurentServiceFeeOnOff", !Boolean(globalRateFormik?.values?.restaurentServiceFeeOnOff))} />
                  {globalRateFormik.touched?.restaurentServiceFeeOnOff && globalRateFormik.errors?.restaurentServiceFeeOnOff ? (<div className='req-error'>{globalRateFormik?.errors?.restaurentServiceFeeOnOff}</div>) : null}
                </div>
              </div>


              <div className='d-flex gap-3'>
                <label htmlFor='minWidhdrawlAmount' className=" col-form-label">Min withdrawal amount</label>
                <div className="">
                  <input type="text" className="form-control" id='minWidhdrawlAmount' placeholder="0" name='minWidhdrawlAmount' onChange={globalRateFormik.handleChange} onBlur={globalRateFormik.handleBlur} value={globalRateFormik.values?.minWidhdrawlAmount} />
                  {globalRateFormik.touched?.minWidhdrawlAmount && globalRateFormik.errors?.minWidhdrawlAmount ? (<div className='req-error'>{globalRateFormik?.errors?.minWidhdrawlAmount}</div>) : null}
                </div>
              </div>
              <div className='d-flex gap-3'>
                <label htmlFor='widhdrawlCharge' className=" col-form-label">withdrawal charge (%)</label>
                <div className="">
                  <input type="text" className="form-control" id='widhdrawlCharge' placeholder="0" name='widhdrawlCharge' onChange={globalRateFormik.handleChange} onBlur={globalRateFormik.handleBlur} value={globalRateFormik.values?.widhdrawlCharge} />
                  {globalRateFormik.touched?.widhdrawlCharge && globalRateFormik.errors?.widhdrawlCharge ? (<div className='req-error'>{globalRateFormik?.errors?.widhdrawlCharge}</div>) : null}
                </div>
              </div>

              <div className="d-flex align-items-end"  >
                <button type="submit" className="btn btn-sm btn-primary" disabled={submitLoader}>Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default RestaurantGlobalRate