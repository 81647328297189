import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { uploadFileApi } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import { convertByteToMB } from '../../../utils/HelperFuncitons';
import Spinwheel from '../../common/Spinwheel';

const BlogAdd = ({ isOpen, onClose, formik, loading }) => {

  const fileInputRef = useRef(null);
  const fileUploadHandler = async (e) => {
    try {

      const FILE_ACCEPT = ["image/jpg", "image/jpeg", "image/png"]

      console.log("e.target.value",e.target.value)

      const file = e.target.files[0];
      if (file) {

        if (!FILE_ACCEPT.includes(file.type)) {
          toast.error("Invalid File Format")
          return;
        }

        const fileSizeInMB = convertByteToMB(file.size || 0);

        if (fileSizeInMB > 5) {
          return toast.error("File Size Exceeds. Max Image Should be of 5 MB")
        }

        formik.setFieldTouched('image', true)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "driver")

        const res = await uploadFileApi(formData)
        if (res.status === 200 && res.data?.status === 1) {
          toast.success("File uploaded");
          formik.setFieldValue('image', res.data?.data)
        }
      }

    } catch (error) {
      console.log(error)
      if (error?.response === undefined) {
        toast.error("There was an error uploading your file. Please try again later.")
      }
    }
  }
 


  const editor = useRef(null);

  const config = useMemo(() => {
    return {
      disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
      removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
      readonly: false,
      placeholder: 'Start writing...',
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      enter: "br",
      hidePoweredByJodit: true
    }
  }, []);

  

  console.log(formik?.values)

  return (
    <div className={`modal fade ${isOpen ? 'show' : ''}`} id="blogAddModal" tabIndex="-1" aria-labelledby="blogAddModalLabel" aria-hidden={!isOpen} style={{ display: isOpen ? 'block' : 'none' }} role={isOpen ? "dialog": undefined}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="blogAddModalLabel">{formik?.values?.id ? "Update" : "Add"} New Blog</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={formik?.handleSubmit}>
              {/* <div className="mb-3">
                <label htmlFor="image" className="form-label">Image:</label>
                <input type="file" className="form-control" id="image" name="image" onChange={fileUploadHandler} onBlur={formik?.handleBlur} />
                {formik?.touched?.image && formik?.errors?.image ? (<div className='req-error'>{formik?.errors?.image}</div>) : null}
              </div> */}

              <div className='mb-3'>
                <label className="block font-semibold">Image:</label>

                <div
                  className="border p-3 rounded cursor-pointer bg-gray-100 hover:bg-gray-200 text-center"
                  style={{cursor:"pointer"}}
                  onClick={() => fileInputRef.current?.click()}
                >
                  {formik.values?.image ? formik.values.image?.split("/")?.[1] ? formik.values.image?.split("/")?.[1] : formik.values.image : "Choose a file"}
                </div>

                <input
                  type="file"
                  className="d-none"
                  ref={fileInputRef}
                  name="image"
                  onChange={fileUploadHandler}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="heading" className="form-label">Heading:</label>
                <input type="text" className="form-control" id="heading" name="heading" placeholder='Enter' value={formik?.values?.heading} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                {formik?.touched?.heading && formik?.errors?.heading ? (<div className='req-error'>{formik?.errors?.heading}</div>) : null}
              </div>
              <div>
                <label htmlFor="isPublish">Published</label>
                <div className="form-check form-switch ">
                  <input className="form-check-input" type="checkbox" checked={formik?.values?.isPublish} role="switch" id="isPublish" onChange={() => formik.setFieldValue("isPublish", !formik?.values?.isPublish)} />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="content" className="form-label">Content:</label>
                <JoditEditor
                  ref={editor}
                  value={formik?.values?.content}
                  // config={config}
                  onBlur={(e) => {
                    formik.setFieldTouched("content", true)
                    e === "<p><br></p>" ? formik.setFieldValue("content", "") : formik.setFieldValue("content", e);
                  }}
                  onChange={(newContent) => {}}
                />
                {formik?.touched?.content && formik?.errors?.content ? (<div className='req-error'>{formik?.errors?.content}</div>) : null}
              </div>
              <button type="submit" className="btn btn-primary" disabled={loading}>  {loading ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogAdd;