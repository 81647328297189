import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import {adminGetAllDeletedUserList, adminUserChagneStatusApi, cityByCountryApi, countryListApi, getAllUsersListApi } from '../../../utils/ApiEndpointFunctions';
import BreadCrumb from '../../common/BreadCrumb';
import CustomerTable from './CustomerTable';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useFormik } from 'formik';

const Customers = () => {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [userStatus, setUserStatus] = useState("Active");
  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [confirmStatus, setConfirmStatus] = useState({ status: "", userId: "" })
  const {adminDetail} = useAdminContext();
  let data = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("tab");

  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])

  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleCityChange = (city) => {
    searchFormik.setFieldValue('cityId', city)
  }

  const handleCountryChange = (country) => {
    console.log(country, 'this is count')
    setCityOptions([])
    searchFormik.setFieldValue('countryId', country);
    searchFormik.setFieldValue('cityId', null);
    filterCites(country.value)
  }

  const searchFormik = useFormik({
    initialValues: {
      countryId: adminDetail.role !== "superAdmin" && adminDetail.countryId ? { label: adminDetail.countryName, value: adminDetail.countryId } : null, 
      cityId: null,
    },
  })

  const {limit} = useAdminContext();
  let currentPage = "";

  

  const handleChange = async (event) => {

    if (event.target.name === "searchField") {
      let search1 = event.target.value
      setSearch(search1);
      setLoader(true);
      const res = await getAllUsersListApi(limit, 1, search1, searchFormik.values?.countryId?.label, searchFormik.values?.cityId?.label);
      const usersData = res?.data?.data?.findPartners;
      const totalLength = res?.data?.data?.totalLength;
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setUsers(usersData);
      setSelectedPage(1);
    }
    setLoader(false);
  };
  const debouncedOnChange = debounce(handleChange, 800);

  const handlePageClick = (event) => {
    currentPage = event.selected + 1;
    getUsers(currentPage,limit)
    setSelectedPage(currentPage)
  };

  async function getUsers(page,limit) {
    try {
      setLoader(true);
      const res = await getAllUsersListApi(limit, page, search, searchFormik.values?.countryId?.label, searchFormik.values?.cityId?.label);
      if (res.status === 200 || res.status === 201) {
        const usersData = res?.data?.data?.findPartners;
        const totalLength = res?.data?.data?.totalLength;
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setUsers(usersData);
        setLoader(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  }
  async function getDeletedUsers() {
    try {
      let page = searchParams.get('page')
      page = page ? page : 1
      setLoader(true);
      const res = await adminGetAllDeletedUserList(limit, page, search);
      const usersData = res?.data?.data;
      const totalLength = res?.data?.totalLength;
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setUsers(usersData);
      setLoader(false);
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  }

  const handleChangeStatus = async () => {
    try {
      setLoader(true)
      document.getElementById('confirmationModal').click()
      await adminUserChagneStatusApi(confirmStatus)
      getUsers(selectedPage,limit)
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getUsers(selectedPage,limit);
  }, [searchFormik.values])

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* <BreadCrumb /> */}

            <div className="main-content">
              {/* HEADER */}
              <div className="row">
                <>
                  <ul className="nav nav-tabs">
                    <li className="nav-item ms-3">
                      <button
                        className={`nav-link ${(activeTab === 'active' || activeTab == null) ? 'active' : ''}`}
                      >
                        All
                      </button>
                    </li>
                  </ul>

                  {(activeTab === 'active' || activeTab == null) && (
                    <div className="card" >
                      <CustomerTable
                        setConfirmStatus={setConfirmStatus}
                        loader={loader}
                        users={users}
                        selectedPage={selectedPage}
                        page={page}
                        totalCount={totalCount}
                        handlePageClick={handlePageClick}
                        debouncedOnChange={debouncedOnChange}
                        getUsers={getUsers}
                        countryOptions={countryOptions}
                        cityOptions={cityOptions}
                        filterCites={filterCites}
                        filterCountry={filterCountry}
                        handleCityChange={handleCityChange}
                        handleCountryChange={handleCountryChange}
                        searchFormik={searchFormik}
                      />
                    </div>
                  )}
                </>
              </div>
              <button
                className="d-none"
                id="confirmModalBtn"
                data-bs-toggle="modal"
                data-bs-target="#confirmationModal">
                openmodal
              </button>
              {confirmStatus && <ConfirmationModal
                handleConfirm={handleChangeStatus}
                confirmStatus={confirmStatus}
                message={<>Are you sure you want to
                  <br /> {confirmStatus.status === 'approved' ? 'enable' : confirmStatus.status === 'rejected' ? 'disable' : ""} this User?</>}
              />}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Customers
